import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material';
import App from './App';
import reportWebVitals from './reportWebVitals';


const theme = createTheme({
  typography: {
    fontFamily: "'Itim', sans-serif", // Your desired font family
    // fontFamily: "'EB Garamond', sans-serif",
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <App />
  </ThemeProvider>,
  document.getElementById('root')
);

reportWebVitals();
