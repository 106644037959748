import Button from '@mui/material/Button';
import React from "react";
import Header from "./Header";
import sourcesData from './Sources.json';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const SourceLinks = () => {
  return (
    <>
      <Header />

      <div style={{ width: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '16px 0px' }}>
        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', border: '0px solid #1976d2', borderRadius: '4px', backgroundColor: 'rgba( 255, 255, 255, 0.1)', }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            href="/">
            Home
          </Button>
        </div>
        <table style={{ width: '90%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '2px solid black' }}>
              <th style={{ padding: '8px', textAlign: 'left', fontSize: '24px' }}>Sources</th>
            </tr>
          </thead>
          <tbody>
            {sourcesData.links.map((link, index) => (
              <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                <td style={{ padding: '8px', wordBreak: 'break-all' }}>
                  <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                </td>
              </tr>
            ))}
            <tr key={98098} style={{ borderBottom: '1px solid #ddd' }}>
              <td style={{ padding: '8px', wordBreak: 'break-all' }}>
                Maps data by: <a href="https://latlong.ai/" target="_blank" rel="noopener noreferrer">https://latlong.ai/</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SourceLinks;
