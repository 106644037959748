import React, { useState, useEffect, useRef } from "react";
import StateApi from "./ApiCalls/StateApi";
import AreaApi from './ApiCalls/AreaApi';
import SchemeApi from './ApiCalls/SchemeApi';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import SchemeDateRangeApi from './ApiCalls/SchemeDateRangeApi';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Radio, RadioGroup, FormControlLabel, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import LotusDark from './../assets/lotus-dark.png';
import Button from '@mui/material/Button';
import SamruddhiV1 from './samruddhi_v1/Samruddhi';
import PdfModal from './../components/Pdf/PdfModal';
import Fab from '@mui/material/Fab';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InfoWP from './Pdf/InfoWP';
import ChartPdfModal from './Pdf/ChartPdfModal';
import DashboardInfo from './Templates/DashboardInfo';
import { API_URL } from './../config/config';
import Link from '@mui/material/Link';

const InfographicsBJP1 = React.lazy(() => import('./InfographicsBJP'));

const QueryBox4 = (props) => {

  const mobileView = window.innerWidth <= 768;
  const [stateData, setStateData] = useState(null);
  const [areaData, setAreaData] = useState(null);
  const [schemeData, setSchemeData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedConstituency, setSelectedConstituency] = useState(null);
  const [urlConstituency, setUrlConstituency] = useState(null);
  const [sharedSelectedState, setSharedSelectedState] = useState(null);
  const [sharedSelectedConstituency, setSharedSelectedConstituency] = useState(null);

  const [shared, setShared] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const searchParams = useSearchParams();
  // console.log(query.get('shared'), "query params")


  let dashboard = props.dashboard;
  let setDashboard = props.setDashboard;
  let samruddhiV1 = props.samruddhiV1;
  let samruddhiV2 = props.samruddhiV2;
  let setSamruddhiV1 = props.setSamruddhiV1;
  let setSamruddhiV2 = props.setSamruddhiV2;
  let setPdfShow = props.setPdfShow;
  let pdfShow = props.pdfShow;
  let chartPdfShow = props.chartPdfShow;
  let setChartPdfShow = props.setChartPdfShow;
  let setWpLanguage = props.setWpLanguage;
  let wpLanguage = props.wpLanguage;

  const [selectedSchemes, setSelectedSchemes] = useState(null);
  const [isStateSelected, setIsStateSelected] = useState(null)
  const [isAreaSelected, setIsAreaSelected] = useState(false);
  const [isSchemeSelected, setIsSchemeSelected] = useState(false);
  const [summaryResponse, setSummaryResponse] = useState(null);
  const [dashboardResponse, setDashboardResponse] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [schemeDateRangeData, setSchemeDateRangeData] = useState(null);
  const [minYear, setMinYear] = useState(0);
  const [maxYear, setMaxYear] = useState(0);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [selectedOption, setSelectedOption] = useState('pc');
  const [isHomePage, setIsHomePage] = useState(true);
  // const [isModalOpen, setIsModalOpen] = useState(true);
  const isModalOpen = props.isModalOpen;
  const setIsModalOpen = props.setIsModalOpen;
  const setShowLoader = props.setShowLoader;
  const setIsNativeLanguage = props.setIsNativeLanguage;
  const isNativeLanguage = props.isNativeLanguage;
  const setNativeLanguage = props.setNativeLanguage;
  const setNativeScript = props.setNativeScript;
  const nativeLanguage = props.nativeLanguage
  const setLanguage = props.setLanguage;
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();



  const [isSharedPath, setIsSharedPath] = useState(location.search.includes('shared=true'));
  // console.log(isSharedPath, 'isSharedPath')

  useEffect(() => {
    if (isSharedPath) {
      // console.log(params.area_id, params.scheme_id, params.tab, params.accordian, 'second')
    }

  }, [isSharedPath])

  const constituencyAutocompleteRef = useRef(null);

  const [selectedValue, setSelectedValue] = useState('');

  const languageChange = props.languageChange;
  const setLanguageChange = props.setLanguageChange;

  const handleRadioChange = (event) => {
    const temp3 = event.target.getAttribute("data-scheme-name");
    setSelectedValue(event.target.value);
    setSelectedSchemes(event.target.value);
    setIsSchemeSelected(true);
  };

  const getColorForScheme = (schemeId) => {
    switch (schemeId) {
      case 100001:
        return '#960859'; //woman - jal jeevan
      case 100006:
        return '#960859'; //woman - swach bharath
      case 100007:
        return '#960859'; //woman - ujjwal
      case 100009:
        return '#960859'; //woman - saubhagya
      case 100010:
        return '#784b38'; //poor - garib
      case 100011:
        return '#784b38'; //poor - awas
      case 100002:
        return '#784b38'; //poor - kendra
      case 100108:
        return '#784b38'; //poor - ayushman bharath
      case 100108:
        return '#784b38'; //poor - ayushman bharath
      case 100022:
        return '#0F9328'; //farmer - pmgsy
      case 100129:
        return '#2a72b5'; //youth - svanidi
      case 100171:
        return '#0F9328'; //farmer - soil card
      case 100172:
        return '#2a72b5'; //youth - kaushal
      case 100173:
        return '#2a72b5'; //youth - JanDhan
      case 100174:
        return '#2a72b5'; //youth - eShram
      case 100218:
        return '#2a72b5'; //youth - shram yogi
      case 100220:
        return '#960859'; //youth - ujala
      case 100219:
        return '#784b38'; //poor - NSAP
      case 100175:
        return '#960859'; //woman - DAY -NRLM
      case 100245:
        return '#2a72b5'; //poor - NAPS
      case 100005:
        return '#0F9328'; //poor - NAPS
      case 100303:
        return '#960859'; //youth - Mudra
      default:
        return 'black'; // Default color
    }
  };

  const openSamruddhiV2 = () => {
    setSelectedValue('')
    setSamruddhiV2(true);
    setSamruddhiV1(false);
    setDashboard(false);
    window._paq.push(['trackEvent', 'Button', 'Click', 'Infographics']);
    // eslint-disable-next-line no-undef
    gtag('event', 'click', {
      'event_category': 'Button',
      'event_label': 'Infographics'
    });
    // var element = document.getElementById("wp_button");
    // if (element) {
    //     element.style.display = "none";
    // }
    // handleCloseModal();
  }

  const openSamruddhiV1 = () => {
    setSamruddhiV1(true);
    setSamruddhiV2(false);
    setDashboard(false);
    handleCloseModal();
    window._paq.push(['trackEvent', 'Button', 'Click', 'Videos']);
    // eslint-disable-next-line no-undef
    gtag('event', 'click', {
      'event_category': 'Button',
      'event_label': 'Videos'
    });
  }

  const openDashboard = () => {
    setDashboard(true);
    setSamruddhiV1(false);
    setSamruddhiV2(false);
    handleCloseModal();
    fetchDashboardSummary();
  }

  useEffect(() => {
    if (location.pathname === '/') {
      setIsModalOpen(true)
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
      // setUrlConstituency(urlParameters.area_id);
      // setSelectedSchemes(urlParameters.scheme_id);
    }
  }, [location])



  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isSchemeSelected || !summaryResponse) {
      setIsModalOpen(true);
    }
  }, [isSchemeSelected, summaryResponse]);

  useEffect(() => {
    // console.log('selected state yavdhu', selectedState)
    setIsNativeLanguage(selectedState && selectedState.native_lang_hindi);
    setNativeLanguage(selectedState && selectedState.state_lang);
    setNativeScript(selectedState && selectedState.native_lang_script);
  }, [selectedState])

  const onStateFetched = (data) => {
    setStateData(data);
  };


  const onAreaFetched = (data) => {
    setAreaData(data);
  };

  const onSchemeFetched = (data) => {
    setSchemeData(data);
  };

  const handleSchemeChange = (event, newValue) => {
    setSelectedSchemes(newValue);
    setIsSchemeSelected(true);
  };

  // useEffect(() => {
  //   fetchSummaryResponse();
  // }, []);


  useEffect(() => {
    if (isSchemeSelected && schemeDateRangeData) {
      const selectedSchemeId = parseInt(selectedSchemes, 10);
      // console.log('selectedSchemeId', selectedSchemeId)
      if (schemeData.some(schemeData => schemeData.id === selectedSchemeId)) {
        fetchSummaryResponse();
      }
      fetchDashboardSummary();
    }
  }, [selectedSchemes, schemeDateRangeData, languageChange]);

  useEffect(() => {
    fetchDashboardSummary();
  }, [languageChange]);

  // useEffect(() => {
  //   if(languageChange != 'english' && languageChange != 'hindi'){
  //     setLanguageChange('english');
  //     setLanguage('english');
  //   }
  // }, [ schemeDateRangeData, selectedConstituency])

  const fetchSummaryResponse = () => {
    setShowLoader(true);

    const newSelectedData = {
      // startYear: schemeDateRangeData.date_range[0].min,
      // endYear: schemeDateRangeData.date_range[0].max,
      startYear: 2000,
      endYear: 2024,
      constituency: selectedConstituency,
      schemes: selectedSchemes,
      language: languageChange,
    };

    if (!isSharedPath) {
      navigate(`/${selectedConstituency.id}/${selectedSchemes}`, { replace: false });
    }

    setSelectedData(newSelectedData);
    const jsonString = JSON.stringify(newSelectedData);

    const apiEndpoint = `${API_URL}/samruddhi_v2/get_summary`;

    let payload = {
      // area_id: isHomePage ? newSelectedData.constituency.id : urlConstituency,
      area_id: newSelectedData.constituency.id,
      scheme_id: newSelectedData.schemes,
      min: newSelectedData.startYear,
      max: newSelectedData.endYear,
      language: newSelectedData.language,
      st_code: selectedState && selectedState.st_code,
    };
    // console.log(isNativeLanguage, 'isNativeLanguage')
    let native_languages_available = ['kannada', 'telugu', 'hindi', 'english', 'tamil', 'gujarati', 'marathi', 'bengali', 'odia', 'punjabi', 'urdu', 'malayalam', 'assamese']
    if (payload.language != 'english' && payload.language != 'hindi' && payload.language != nativeLanguage) {
      if (!isNativeLanguage && native_languages_available.includes(nativeLanguage)) {
        payload.language = nativeLanguage;
        setLanguage(nativeLanguage)
        setLanguageChange(nativeLanguage)
      } else {
        payload.language = 'english';
        setLanguage('english')
        setLanguageChange('english')
      }
    }
    axios.post(apiEndpoint, payload)
      .then(response => {
        if (response.data.summary.length > 0) {
          setSummaryResponse(response.data); // Update the state with the API response
          setSelectedData(newSelectedData);
          handleCloseModal();
          // console.log(response.data);
        } else {
          console.log('error')
          // console.log(apiEndpoint)
        }
        setShowLoader(false);

      })
      .catch(error => {
        console.error('Error making API call:', error);
      });
  };

  const fetchDashboardSummary = async () => {
    selectedConstituency && setShowLoader(true);
    try {
      let response = await axios.get(`${API_URL}/samruddhi_v2/get_scheme_summary?language=${languageChange}&area_id=${selectedConstituency.id}`);
      if (response) {
        setDashboardResponse(response.data); // Update the state with the API response
        handleCloseModal();
        setShowLoader(false);
        // console.log(response.data, 'dashboardsummary');
      } else {
        console.log('error')
      }
    } catch (error) {
      console.error('Error fetching scheme data:', error);
    }

    const native_languages_available = ['kannada', 'telugu', 'hindi', 'english', 'tamil', 'gujarati', 'marathi', 'bengali', 'odia', 'punjabi', 'urdu', 'malayalam', 'assamese']
    if (languageChange != 'english' && languageChange != 'hindi' && languageChange != nativeLanguage) {
      if (!isNativeLanguage && native_languages_available.includes(nativeLanguage)) {
        // languageChange = nativeLanguage
        setLanguage(nativeLanguage)
        setLanguageChange(nativeLanguage)
      } else {
        // languageChange = 'english'
        setLanguage('english')
        setLanguageChange('english')
      }
    }

  }

  const onSchemeDateRangeFetched = (data) => {
    setSchemeDateRangeData(data);
  };

  function focusAutocomplete() {
    // setTimeout(() => {
    //   if (constituencyAutocompleteRef.current) {
    //     const inputElement = constituencyAutocompleteRef.current.querySelector('input');
    //     if (inputElement) {
    //       inputElement.focus();
    //     }
    //   }
    // }, 0);
  }

  useEffect(() => {
    if (schemeDateRangeData && schemeDateRangeData.date_range) {
      const dateRange = schemeDateRangeData.date_range[0];
      setMinYear(dateRange.min);
      setMaxYear(dateRange.max);
    }
  }, [schemeDateRangeData]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const handlePopstate = () => {
      setIsModalOpen(true); // Open the modal when the user clicks the back button
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // useEffect(() => {
  //   if (samruddhiV1) {
  //     // history.push('/samruddhi_v1');
  //     navigate(`/samruddhi_v1/${selectedConstituency.id}/${selectedState.id}`);
  //   }
  // }, [samruddhiV1, navigate])

  // console.log(selectedConstituency, 'selectedConstituency')

  useEffect(() => {
    if (query.get('shared') == 'true') {
      if (shared) return
      const sharedStateId = query.get('state_id');
      const sharedConstituencyId = query.get('area_id');
      if (stateData) {
        const selectedStateItem = stateData.states.filter(item => {
          if (item.id == sharedStateId) {
            // console.log(item, "item")
            return item
          }
        })
        setSelectedState(selectedStateItem[0])
        setIsStateSelected(true)
      }
      // console.log(areaData)
      if (areaData) {
        const selectedConstituencyItem = areaData.constituencies.filter(item => {
          if (item.id == sharedConstituencyId) {
            // console.log(item)
            return item
          }
        })
        // console.log(selectedConstituencyItem)
        setSelectedConstituency(selectedConstituencyItem[0])
        setIsAreaSelected(true)
        setShared(true);
        setSharedSelectedState(sharedStateId)

        setSharedSelectedConstituency(sharedConstituencyId)
        setSamruddhiV1(true)
        handleCloseModal()
      }

    }
  }, [shared, stateData, areaData])

  const togglePdfShow = () => {
    setIsModalOpen(false);
    setSummaryResponse(null);
    setSamruddhiV1(false);
    setSamruddhiV2(false);
    window._paq.push(['trackEvent', 'Button', 'Click', 'Shwetapatra']);
    // eslint-disable-next-line no-undef
    gtag('event', 'click', {
      'event_category': 'Button',
      'event_label': 'Shwetapatra'
    });
    // setPdfShow(prevState => !prevState);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "inherit",
        width: 'inherit',
        paddingTop: '12px',
      }}
    >
      <StateApi onStateFetched={onStateFetched} ></StateApi>
      <AreaApi onAreaFetched={onAreaFetched} selectedState={selectedState} setSelectedConstituency={setSelectedConstituency} />
      <SchemeApi onSchemeFetched={onSchemeFetched} selectedConstituency={selectedConstituency} />
      {selectedConstituency && selectedSchemes && (
        < SchemeDateRangeApi
          areaId={selectedConstituency.id}
          schemeId={selectedSchemes}
          onDataFetched={onSchemeDateRangeFetched}
        />
      )}

      {isModalOpen && (
        <Modal
          open={isModalOpen}
          // onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: (!schemeData || samruddhiV1) ? '10%' : '3%',  // Adjust the top position as needed
              left: '50%',
              transform: 'translateX(-50%)', // Center horizontally
              width: '80%', // Adjust the width as needed
              maxWidth: 400, // Set a maximum width
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 2,
            }}
          >
            {(summaryResponse || samruddhiV1) && (
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <IconButton onClick={handleCloseModal} style={{ padding: '4px' }}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </div>
            )}
            <div className='query_details' >
              {
                (!selectedState) &&
                <>
                  <div className='area_selection-div'>
                    <Button variant="contained" size="large" style={{ backgroundColor: 'white', color: 'black', width: mobileView ? '90%' : '96%', padding: '1rem', border: '1px solid rgba(0,0,0,0.3)', borderRadius: '4px' }} onClick={() => togglePdfShow()} id="wp_button" >
                      All India
                    </Button>
                  </div>
                  <hr style={{ width: '100%', color: 'black', margin: '0px' }}></hr>
                  {/* <div className='area_selection-div'>
                    <Button variant="contained" size="large" style={{ backgroundColor: 'white', color: 'black', width: mobileView ? '90%' : '96%', padding: '1rem', border: '1px solid rgba(0,0,0,0.3)', borderRadius: '4px' }} href="https://sankalpapatra.in/" target="_blank">Sankalp Patra</Button>
                  </div>
                  <hr style={{ width: '100%', color: 'black', margin: '0px' }}></hr> */}
                </>
              }

              {!schemeData || samruddhiV1 ?
                <div className='area_selection-div'>
                  <div style={{ width: mobileView ? '90%' : '96%' }}>
                    {
                      stateData?.states &&
                      <Autocomplete
                        id="state-autocomplete"
                        options={stateData && stateData.states}
                        getOptionLabel={(option) => option?.name || ''}
                        value={selectedState}
                        onChange={(event, newValue) => {
                          if (newValue == null) {
                            setIsStateSelected(false)
                          }
                          setSelectedState(newValue);
                          setIsStateSelected(true);
                        }}
                        clearOnBlur
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"State"}
                            style={{ fontFamily: "'Itim', sans-serif", color: 'red' }}
                          />
                        )}
                      />
                    }
                  </div>

                  <div style={{ width: mobileView ? '90%' : '96%' }}>
                    {(selectedState && areaData) && (
                      <Autocomplete
                        id="constituency-autocomplete"
                        ref={constituencyAutocompleteRef}
                        options={areaData.constituencies}
                        getOptionLabel={(option) => option?.name || ''}
                        value={selectedConstituency}
                        onChange={(event, newValue) => {
                          setSelectedConstituency(newValue);
                          setIsAreaSelected(true);
                          if (samruddhiV1) {
                            handleCloseModal();
                          }
                        }}
                        openOnFocus
                        clearOnBlur
                        disablePortal
                        // clearIcon={null}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Constituency"}
                            style={{ fontFamily: "'Itim', sans-serif", color: 'red' }}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                :
                <div
                  className="styled-div_area"
                  style={{ width: '84%', height: '40px', display: 'flex', alignItems: 'center' }}
                  onClick={() => {
                    setSchemeData(null);
                    focusAutocomplete();
                  }}
                >
                  {(selectedConstituency && selectedConstituency.name != 'State Level Data') && selectedConstituency.name}  {(selectedConstituency && selectedConstituency.name != 'State Level Data') && `,`} {selectedState && selectedState.name}
                </div>
              }

              {(selectedState && isAreaSelected) && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', }}>
                    <Button variant={(samruddhiV1 && !dashboard) ? 'contained' : 'outlined'} onClick={() => { openSamruddhiV1(); }}>Videos</Button>
                    <Button variant={(samruddhiV2 && !dashboard) ? 'contained' : 'outlined'} onClick={() => { openSamruddhiV2(); }}>Infographics</Button>
                    <Button variant={(dashboard && !samruddhiV2 && !samruddhiV2) ? 'contained' : 'outlined'} onClick={() => { openDashboard(); }}>Dashboard</Button>
                  </div>

                  {(samruddhiV2 && schemeData) && (
                    <div style={{ width: mobileView ? '83%' : '90%', maxHeight: '60vh', overflowY: 'auto' }} className={"styled-div"}>
                      <RadioGroup
                        aria-label="scheme"
                        name="scheme"
                        value={selectedValue}
                        onChange={handleRadioChange}
                      >
                        {schemeData &&
                          schemeData.map((scheme) => (
                            <FormControlLabel
                              key={scheme.id}
                              value={scheme.id}
                              control={<Radio />}
                              label={scheme.scheme_name}
                              style={{ color: getColorForScheme(scheme.id) }}
                              data-scheme-name="ello"
                            // style={{height: '50px'}}
                            />
                          ))}
                      </RadioGroup>
                    </div>
                  )}
                </>
              )}
              <div style={{ display: 'flex', justifyContent: 'end', width: '100%', maxWidth: '340px', }}>
                <Link href="/sources" underline="always" >
                  Data sources
                </Link>
              </div>


            </div>
          </Box>
        </Modal>
      )}
      {(summaryResponse && samruddhiV2) ?
        <React.Suspense>
          <InfographicsBJP1
            summaryResponse={summaryResponse}
            selectedCardId={selectedCardId}
            selectedConstituencyName={languageChange != 'english' && languageChange != 'hindi' ? 'Bagalkot' : summaryResponse.summary[0].area_name}
            languageChange={languageChange}
            setIsModalOpen={setIsModalOpen}
            setShowLoader={setShowLoader}
            focusAutocomplete={focusAutocomplete}
            selectedState={selectedState}
            dashboard={dashboard}
          ></InfographicsBJP1>
        </React.Suspense>
        :
        (selectedState && selectedConstituency && samruddhiV1) &&
        <SamruddhiV1 state={selectedState} area={selectedConstituency} setIsModalOpen={setIsModalOpen} shared={query.get('shared') == 'true'} sharedState={sharedSelectedState} sharedSelectedConstituency={sharedSelectedState} pdfShow={pdfShow} tab={query.get('tab')} acc={query.get('acc')}></SamruddhiV1>
      }
      {pdfShow ? <PdfModal setPdfShow={setPdfShow} samruddhiV1={samruddhiV1} pdfShow={pdfShow} setIsModalOpen={setIsModalOpen} /> : null}

      {chartPdfShow ? <ChartPdfModal chartPdfShow={chartPdfShow} setChartPdfShow={setChartPdfShow} setIsModalOpen={setIsModalOpen} samruddhiV1={samruddhiV1} /> : null}

      {(!isModalOpen && !samruddhiV2 && !samruddhiV1 && !dashboard) &&
        <InfoWP setIsModalOpen={setIsModalOpen} setPdfShow={setPdfShow} setChartPdfShow={setChartPdfShow} setWpLanguage={setWpLanguage} wpLanguage={wpLanguage}></InfoWP>
      }
      {(!isModalOpen && !samruddhiV2 && !samruddhiV1 && dashboard && dashboardResponse) &&
        <DashboardInfo dashboardResponse={dashboardResponse} setIsModalOpen={setIsModalOpen} setDashboard={setDashboard} dashboard={dashboard} languageChange={languageChange} setShowLoader={setShowLoader}></DashboardInfo>
      }
    </div>
  );
}

export default QueryBox4;
