import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MapShared from './MapShared';
import DrawerData from './DrawerData';
var sharedOpen = 0

const SamruddhiShared = () => {
  const params = useParams();
  const [initialUI, setInititalUI] = useState(false)
  const [videos, setVideos] = useState(null);
  const [beneficiaryData, setBeneficiaryData] = useState(null);
  const [schemeData, setSchemeData] = useState(null);
  const [manifestoData, setManifestoData] = useState(null);
  const [shareData, setShareData] = React.useState([params.area_name,params.aa_id,params.aa_order])
  const [open, setOpen] = React.useState(false);
  const [videosLoading, setVideosLoading] = React.useState(false);
  const [beneficiaryLoading, setBeneficiaryLoading] = React.useState(false);
  const [schemeLoading, setSchemeLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  let anchorClicked = 0;
  
  // console.log(videos, ' videos data')


  useEffect(() => {
    
    if(beneficiaryData  && videos ){
      // console.log(beneficiaryData, 'beneficiaryData11111');

      if(params.tab == 0){
        for (let i = 0; i < videos.length; i++) {
          if (videos[i].video_id == params.accordian ) {
            var position = i;
            // setPageNumber(parseInt(position/10));  
            // console.log(`Index of ${params.accordian}: ${i}`);
            const removedItem = videos.splice(i, 1)[0];
            // console.log(removedItem, 'removed item');
            videos.unshift(removedItem);
            // console.log(videos, "updated videos");

            // console.log(parseInt(position/10), "pagenumber") ;

            break; 
          }
        } 
      }else{
        for (let i = 0; i < beneficiaryData.length; i++) {
          if (beneficiaryData[i].beneficiary_id == params.accordian ) {
            var position = i;
            // setPageNumber(parseInt(position/10));  
            // console.log(`Index of ${params.accordian}: ${i}`);
            const removedItem = beneficiaryData.splice(i, 1)[0];
            // console.log(removedItem, 'removed item');
            beneficiaryData.unshift(removedItem);
            // console.log(beneficiaryData, "updated beneficiaryData videos");
            // console.log(parseInt(position/10), "pagenumber") ;

            break; 
          }
        }  
      }     
      
      const timer = setTimeout(() => {
        if(sharedOpen == 0){
          setOpen(true)
          sharedOpen = 1
        }          
        document.getElementById(`simple-tab-${params.tab}`).click();
        const anchor = `l_${params.accordian}`   
        if (anchor && beneficiaryData && videos) {       
          const anchorEl = document.getElementById(anchor);
          anchorEl.style.backgroundColor = "rgba(137,207,240,0.9)";
          const anchorElSum = document.getElementById(`l_s_${params.accordian}`);
          if (anchorEl != null && (anchorClicked == 0) ) {
            anchorEl.scrollIntoView();
            anchorElSum.click();
            anchorClicked = 1
          }
        }
        return () => clearTimeout(timer);
      }, 2000);    
    }

  },[beneficiaryData, videos]);

  return (
    <>
      <MapShared videos={videos} setVideos={setVideos} setInititalUI={setInititalUI} initialUI={initialUI} setBeneficiaryData={setBeneficiaryData}  setSchemeData={setSchemeData} setManifestoData={setManifestoData} setShareData={setShareData} shareData={shareData} setOpen={setOpen} setVideosLoading={setVideosLoading} setBeneficiaryLoading={setBeneficiaryLoading} setSchemeLoading={setSchemeLoading}/>
      
      <DrawerData videos={videos} beneficiaryData={beneficiaryData} schemeData={schemeData} setOpen={setOpen} open={open} shareData={shareData}  manifestoData={manifestoData} videosLoading={videosLoading} beneficiaryLoading={beneficiaryLoading} schemeLoading={schemeLoading} pageNumber={pageNumber} />
    </>
  );
}

export default SamruddhiShared;