import React from "react";
import { useTable, useSortBy } from "react-table";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Fab from '@mui/material/Fab';
import html2canvas from 'html2canvas';
import DownloadIcon from "@mui/icons-material/Download";
import LanguageConfig from "./../LanguageConfig/LanguageConfig.json";
import Link from '@mui/material/Link';

const DashboardInfographics = (props) => {
  const dashboardResponse = props.dashboardResponse;
  const setIsModalOpen = props.setIsModalOpen;
  let setDashboard = props.setDashboard;
  let dashboard = props.dashboard;
  let languageChange = props.languageChange;
  let setShowLoader = props.setShowLoader;

  const schemeSummary = dashboardResponse.scheme_summary;
  const firstKey = Object.keys(schemeSummary)[0];
  const firstArray = schemeSummary[firstKey];

  let areaName = ' '; // Default value

  if (firstArray && firstArray.length > 0 && firstArray[0].area_name !== undefined) {
    areaName = firstArray[0].area_name;
  }

  const formatNumber = (number) => {
    return number && number.toLocaleString('en-IN');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  }

  const handleDownloadImage = () => {
    const table = document.getElementById('dashboard-table');
    html2canvas(table).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = areaName ? `${areaName}.png` : 'dashboard_image.png';
      link.href = imgData;
      link.click();
    });
  };

  const findFirstValidValue = (summary) => {
    for (let i = 0; i < summary.length; i++) { // Exclude the last element
      const currentData = summary[i];
      if (currentData.total_beneficiaries !== null && currentData.total_beneficiaries !== 0) {
        return {
          value: currentData.total_beneficiaries,
          year: currentData.year
        };
      }
    }
    return null;
  };

  const calculateTotalBeneficiaries = (schemeId, schemeArray) => {
    // console.log(schemeArray, 'schemeArray');

    if (schemeId === 100108) {  // Ayushman Bharat Yojana
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Beneficiaries ${formatDate(schemeArray[0].year)}`;
    } else if (schemeId === 100001) {  // Jal Jeevan Mission
      // Custom calculation for scheme ID 100001
      const beneficiariesRatio =
        schemeArray[0].total_beneficiaries !== 0 && schemeArray[1].total_beneficiaries !== 0
          ? Math.round(schemeArray[1].total_beneficiaries - schemeArray[0].total_beneficiaries) < 2
            ? (Math.round(schemeArray[1].total_beneficiaries - schemeArray[0].total_beneficiaries)).toFixed(2)
            : Math.round(schemeArray[1].total_beneficiaries - schemeArray[0].total_beneficiaries)
          : 0;
      let display_value = `${formatNumber(beneficiariesRatio)} Tap Connections (${schemeArray[0].min_year} - ${schemeArray[1].max_year})`;
      return display_value;
    } else if (schemeId === 100002) {  // Janaushadhi Kendra
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${totalBeneficiariesSum} Kendras (2014 - ${schemeArray[0].max_year})`;
    } else if (schemeId === 100011) {  // Pradhan Mantri Aawas Yojana
      const value2014 = schemeArray.find(item => new Date(item.year).getFullYear() === 2014).total_beneficiaries;

      const value2019 = schemeArray.filter(item => new Date(item.year).getFullYear() >= 2014 && new Date(item.year).getFullYear() <= 2019).reduce((sum, item) => sum + item.total_beneficiaries, 0);

      const value2023 = schemeArray.filter(item => new Date(item.year).getFullYear() >= 2014 && new Date(item.year).getFullYear() <= 2023).reduce((sum, item) => sum + item.total_beneficiaries, 0);

      const totalBeneficiariesFirst5 = schemeArray.slice(0, 5).reduce((sum, element) => sum + element.total_beneficiaries, 0);

      const totalBeneficiariesAll = schemeArray.reduce((sum, element) => sum + element.total_beneficiaries, 0);

      const ratio = schemeArray[0].total_beneficiaries !== 0 ? (totalBeneficiariesAll - schemeArray[0].total_beneficiaries) : (totalBeneficiariesAll - parseInt(value2019, 10));

      // const formattedRatio = ratio < 2 ? ratio.toFixed(1) : Math.round(ratio);

      let display_value = `${formatNumber(ratio)} Houses Built (${schemeArray[0].min_year} - ${schemeArray[1].max_year})`;
      return display_value;
    } else if (schemeId === 100010) {  // Pradhan Mantri Garib Kalyan Anna Yojana
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Beneficiaries (${schemeArray[0].max_year})`;
    } else if (schemeId === 100022) {  // Pradhan Mantri Gram Sadak Yojana
      const totalBeneficiariesFirst5 = schemeArray.slice(0, 15).reduce((sum, element) => sum + element.total_beneficiaries, 0);
      const totalBeneficiariesAll = schemeArray.reduce((sum, element) => sum + element.total_beneficiaries, 0);
      const ratio = totalBeneficiariesFirst5 !== 0 ? (totalBeneficiariesAll / totalBeneficiariesFirst5) : 0;

      const totalBeneficiariesGSY = schemeArray[0].total_beneficiaries + schemeArray[1].total_beneficiaries + schemeArray[2].total_beneficiaries + schemeArray[3].total_beneficiaries;
      const denominatorGSY = schemeArray[0].total_beneficiaries + schemeArray[1].total_beneficiaries;
      const sumOfFirstThree = schemeArray[0].total_beneficiaries + schemeArray[1].total_beneficiaries + schemeArray[2].total_beneficiaries;

      const ratioOfIncrease = denominatorGSY > 0 ? (totalBeneficiariesGSY - denominatorGSY) : (totalBeneficiariesGSY - schemeArray[2].total_beneficiaries);
      // const formattedRatio = ratioOfIncrease < 2 ? ratioOfIncrease.toFixed(1) : Math.round(ratioOfIncrease);

      // const display_value = sumOfFirstThree > 0 ? `${formattedRatio}x` : schemeArray[3].total_beneficiaries;
      return `${formatNumber(ratioOfIncrease)} Roads built (Kms) (2014 - 2023)`;
    } else if (schemeId === 100172) {  // Pradhan Mantri Kaushal Vikas Yojana
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Enrolled (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100129) {  // Pradhan Mantri SVANidhi Yojana
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Beneficiaries (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100009) {  // Pradhan Mantri Saubhagya Yojana
      let display_value = `${schemeArray[0].total_coverage}% Total Connections as of (${schemeArray[schemeArray.length - 1].electrified_year})`;
      return display_value;
    } else if (schemeId === 100007) {  // Pradhan Mantri Ujjwala Yojana     
      const cummulativeConnections = schemeArray[0].total_beneficiaries + schemeArray[1].total_beneficiaries;
      const ratio = cummulativeConnections - schemeArray[0].total_beneficiaries;
      // const formattedRatio = schemeArray[0].total_beneficiaries !== 0 ? (ratio < 2 ? ratio.toFixed(1) : Math.round(ratio)) : schemeArray[1].total_beneficiaries;
      // const displayValue = schemeArray[0].total_beneficiaries !== 0 ? `${formattedRatio}x` : schemeArray[1].total_beneficiaries;
      return `${formatNumber(ratio)} Total Connections (${schemeArray[0].min_year} - ${schemeArray[1].max_year})`;
    } else if (schemeId === 100171) {  // Soil Health Card
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Soil Tests (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100006) {  // Swachh Bharat Mission
      // Initialize variables
      let beneficiariesIihl = null;
      let beneficiariesCsc = null;

      // Iterate through the array
      schemeArray.forEach(entry => {
        if (entry.scheme_type === "iihl") {
          beneficiariesIihl = entry.total_beneficiaries;
        } else if (entry.scheme_type === "community sanitary complexes") {
          beneficiariesCsc = entry.total_beneficiaries;
        }
      });
      return `${formatNumber(beneficiariesIihl)} Toilets (2014 - ${formatDate(schemeArray[schemeArray.length - 1].year)})`;
    } else if (schemeId === 100174) {  // eShram
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Registrations  (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100303) {  // Mudra
      return `${formatNumber(schemeArray[0].total_beneficiaries)} Accounts Opened (till ${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100173) {  // Jan Dhan
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${totalBeneficiariesSum}% Households  (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100175) {  // DAY
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Households  (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100005) {  // Kissan Saman Nidhi
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} Beneficiaries (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100218) {  // Shram Yoi Maandhan
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      let display_value = totalBeneficiariesSum - schemeArray[0].total_beneficiaries;
      return `${formatNumber(display_value)} Beneficiaries (${formatDate(schemeArray[0].year)} - ${formatDate(schemeArray[schemeArray.length - 1].year)})`;
    } else if (schemeId === 100219) {  // NSAP
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      let display_value = totalBeneficiariesSum - schemeArray[0].total_beneficiaries;
      return `${formatNumber(display_value)} Beneficiaries (${formatDate(schemeArray[0].year)} - ${formatDate(schemeArray[schemeArray.length - 1].year)})`;
    } else if (schemeId === 100220) {  // Ujala
      let totalBeneficiariesSum = schemeArray.reduce((sum, scheme) => sum + scheme.total_beneficiaries, 0);
      return `${formatNumber(totalBeneficiariesSum)} LED's Distributed (${formatDate(schemeArray[0].year)})`;
    } else if (schemeId === 100245) {  // NAPS
      let totalBeneficiariesSum = 0;
      schemeArray.forEach(summaryItem => {
        totalBeneficiariesSum += summaryItem.total_beneficiaries;
      });
      const firstValidData = findFirstValidValue(schemeArray);
      const ratio = (totalBeneficiariesSum && totalBeneficiariesSum === firstValidData.value) ? totalBeneficiariesSum : totalBeneficiariesSum - firstValidData.value;
      const year = (totalBeneficiariesSum === firstValidData.value) ? `${formatDate(schemeArray[schemeArray.length - 1].year)}` : `${formatDate(firstValidData.year)} - ${formatDate(schemeArray[schemeArray.length - 1].year)}`;
      return `${formatNumber(ratio)} Beneficiaries (${year})`;
    } else {
      let display_value = schemeArray[0].total_beneficiaries;
      console.log(schemeId, 'schemeId')
      return display_value;
    }
  };



  const columns = React.useMemo(
    () => [
      {
        Header: LanguageConfig[languageChange] ? LanguageConfig[languageChange].scheme_name : 'Scheme Name',
        accessor: 'scheme_name',
      },
      {
        Header: LanguageConfig[languageChange] ? LanguageConfig[languageChange].benefits : 'Benefits',
        accessor: 'total_beneficiaries',
        Cell: ({ row }) => formatNumber(calculateTotalBeneficiaries(row.original.scheme_id, row.original.schemeArray)),
      },
    ],
    [languageChange]
  );

  const data = React.useMemo(() => {
    if (!dashboardResponse || !dashboardResponse.scheme_summary) return [];

    const schemeData = {};

    Object.values(dashboardResponse.scheme_summary).forEach(schemeArray => {
      schemeArray.forEach(scheme => {
        if (scheme.total_beneficiaries !== 0) {
          if (!schemeData[scheme.scheme_id]) {
            schemeData[scheme.scheme_id] = {
              scheme_id: scheme.scheme_id, // Add scheme_id
              scheme_name: scheme.scheme_name,
              total_beneficiaries: 0,
              schemeArray: schemeArray
            };
          }
          schemeData[scheme.scheme_id].total_beneficiaries += scheme.total_beneficiaries;
        }
      });
    });

    return Object.values(schemeData);
  }, [dashboardResponse]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const handleBackClickDashboard = () => {
    setIsModalOpen(true);
    setDashboard(false);
  }

  return (
    <>
      <div style={{ display: dashboardResponse ? 'flex' : 'none', alignItems: 'center', gap: '10px' }}>
        <Fab variant="extended" size="small" style={{ padding: '6px', zIndex: '1' }} color="primary" onClick={() => handleBackClickDashboard()}>
          <ArrowBackIcon color="white" round={true} />
        </Fab>
        <Fab variant="extended" size="small" style={{ padding: '6px', zIndex: '1' }} color="primary" onClick={() => { handleDownloadImage() }}>
          <DownloadIcon color="white" round={true} />
        </Fab>
      </div>
      <table {...getTableProps()} id='dashboard-table' style={{ border: '1px solid black', borderCollapse: 'collapse', margin: '10px 10px 10px 10px', padding: '0px 20px', fontFamily: "'Itim', sans-serif !important", visibility: dashboardResponse ? 'visible' : 'hidden' }}>
        <thead>
          <tr>
            <th colSpan={columns.length} style={{ textAlign: 'center', fontSize: '1.2em', padding: '10px', border: '1px solid black', backgroundColor: '#f2f2f2' }}>
              {areaName ? areaName : 'Area name'}
            </th>
          </tr>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ padding: '10px', border: '1px solid black', backgroundColor: '#f2f2f2' }}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ border: '1px solid black' }}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} style={{ padding: '10px', border: '1px solid black' }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {/* <tfoot>
          <tr>
            <td colSpan="2">
              <div style={{ display: 'flex', justifyContent: 'end', margin: '6px', fontStyle: 'italic' }}>
                <Link href="/sources" underline="always">
                  *Data sources
                </Link>
              </div>
            </td>
          </tr>
        </tfoot> */}
      </table>

    </>
  );
};

export default DashboardInfographics;
