import React, { useEffect, useState } from 'react'
import Header from './subcomponents/DashboardHeader'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Alert } from '@mui/material';
import Divider from '@mui/material/Divider';
import Loader from './subcomponents/DashboardLoader';
import Uploader from './maincomponents/Uploader';
import Reviewer from './maincomponents/Reviewer';
import Admin from './maincomponents/Admin';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MainDashboard() {
    const authUser = useAuthUser()
    const authToken = useAuthHeader();
    const name = authUser.name;
    const uid = authUser.uid;
    const role = authUser.role;
    const [loading, setLoading] = useState(false)

    return (
        <div>
            <Header />
            <Alert icon={<></>} severity="success">
                Welcome, {name}
            </Alert>
            <ToastContainer />
            <Divider orientation="horizontal" variant="middle" flexItem/>
            {
                role == 'uploader' &&
                <Uploader />
            }
            {
                role == 'reviewer' &&
                <Reviewer />
            }
            {
                role == 'admin' &&
                <Admin />
            }
            <Loader open={loading}/>
        </div>
    )
}

export default MainDashboard
