import React, { useEffect, useState } from 'react';
import { redirect, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './Autocomplete.css';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

const filter = createFilterOptions();


export default function FreeSoloCreateOption(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [value, setValue] = React.useState(params.area_name);
  const [initial, setInitial] = useState(true);
  const [autocompleteInputs, setAutocompleteInputs] = useState(searchDistrict);
  const [disabledValue, setDisabledValue] = useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [sharedAreaId, setSharedAreaId] = React.useState(params.aa_id);
  const [sharedAreaOrder, setSharedAreaOrder] = React.useState(params.aa_order);
  let setVideosLoading = props.setVideosLoading;
  let setBeneficiaryLoading = props.setBeneficiaryLoading;
  let setSchemeLoading = props.setSchemeLoading;
  let setInititalUI = props.setInititalUI;
  let setVideos = props.setVideos;
  let setBeneficiaryData = props.setBeneficiaryData;
  let setSchemeData = props.setSchemeData;
  let setManifestoData = props.setManifestoData;
  let map = props.map;
  let setStateData = props.setStateData;
  let setChildData = props.setChildData;
  let setParentData = props.setParentData;
  let setShowLoader = props.setShowLoader;
  let clickedFeature = props.clickedFeature;
  let setAreaOrder = props.setAreaOrder;
  let setShareData = props.setShareData;
  let shareData = props.shareData;
  let setOpen = props.setOpen
  let blurSearchInput;

  // console.log(sharedAreaId, params.aa_id, 'area shared id');


  const handleAlertClose = (event, reason) => {
    setAlertOpen(false);
  };

  const aa_order_names =
    [
      { "aa_order": 3, "name": "Colony" },
      { "aa_order": 4, "name": "Sub-locality" },
      { "aa_order": 5, "name": "Locality" },
      { "aa_order": 6, "name": "Village" },
      { "aa_order": 7, "name": "Taluk" },
      { "aa_order": 8, "name": "District" },
      { "aa_order": 9, "name": "State" },
      { "aa_order": 17, "name": "Corporation" },
      { "aa_order": 18, "name": "Metro" },
      { "aa_order": 27, "name": "MORTH" },
      { "aa_order": 33, "name": "Polling Station" },
      { "aa_order": 37, "name": "Assembly Constituency" },
      { "aa_order": 38, "name": "Parlimentary Constituency" },
      { "aa_order": 55, "name": "Pincode" }
    ]

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleAlertClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (sharedAreaId) {
      if (sharedAreaId) {
        setShowLoader(true);
        setInitial(false);
        setInititalUI(false);
        setVideosLoading(true);
        setBeneficiaryLoading(true);
        setSchemeLoading(true);

        //map area data 
        axios({
          method: 'GET',
          // url: `https://samruddhi_api.latlong.ai/area_information?order=${sharedAreaOrder}\&search=area&area_id=${sharedAreaId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`,
          url: `https://samruddhi_api_v2.latlong.in/samruddhi_v1/area_information?search=area&area_id=${sharedAreaId}`,
          mode: 'no-cors'
        }).then((response) => {
          setShowLoader(false);

          const timer = setTimeout(() => {
            setOpen(true);
            return () => clearTimeout(timer);
          }, 3000);

          // console.log(response.data.data, "map_data area");
          setAreaOrder(sharedAreaOrder);
          setStateData(response.data.data)

          map.current.fitBounds([
            response.data.data[0].properties.centroid.geometry.coordinates,
            response.data.data[0].properties.centroid.geometry.coordinates
          ], { padding: { top: 100, bottom: 100, left: 100, right: 100 }, maxZoom: 7 });

          if (response.data.data[0].properties.aa_order == 36 || response.data.data[0].properties.aa_order == 37) {
            map.current.fitBounds([
              response.data.data[0].properties.bbox[0],
              response.data.data[0].properties.bbox[1]
            ], { padding: { top: 40, bottom: 40, left: 40, right: 40 }, });
            //   map.current.flyTo({
            //     center:response.data.data[0].properties.centroid.geometry.coordinates,
            //     zoom: 10
            //   })
          }

        }).catch((error) => {
          // console.log(error);
        })

        //ministers-video data 
        axios({
          method: 'GET',
          // url: `https://samruddhi_api.latlong.ai/scheme_information?area_id=${sharedAreaId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`,
          url: `https://samruddhi_api_v2.latlong.in/samruddhi_v1/scheme_information?area_id=${sharedAreaId}`,
          mode: 'no-cors'
        }).then((response) => {
          // console.log(response.data.data, 'ministers videos')
          setVideosLoading(false);
          setVideos(response.data.data)
        }).catch((error) => {
          // console.log(error);
        })

        //benificiary-video data 
        axios({
          method: 'GET',
          // url: `https://samruddhi_api.latlong.ai/beneficiary_information?area_id=${sharedAreaId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`,
          url: `https://samruddhi_api_v2.latlong.in/samruddhi_v1/beneficiary_information?area_id=${sharedAreaId}`,
          mode: 'no-cors'
        }).then((response) => {
          // console.log(response.data.data, 'benefeciary videos')
          setBeneficiaryLoading(false);
          setBeneficiaryData(response.data.data)
        }).catch((error) => {
          // console.log(error);
        })




        //map child data       
        axios({
          method: 'GET',
          // url: `https://samruddhi_api.latlong.ai/area_information?order=${sharedAreaOrder}&search=child&area_id=${sharedAreaId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`,
          url: `https://samruddhi_api_v2.latlong.in/samruddhi_v1/area_information?search=child&area_id=${sharedAreaId}`,
          mode: 'no-cors'
        }).then((response) => {
          setShowLoader(false);
          // console.log(response.data.data, "map_child_data")
          setChildData(response.data.data);
          // map.current.fitBounds([
          //   response.data.data[0].properties.bbox.geometry.coordinates,
          //   response.data.data[0].properties.bbox.geometry.coordinates
          // ], {padding: {top: 100, bottom:100, left: 100, right: 100}, maxZoom: 7});
        }).catch((error) => {
          // console.log(error);
        })

        // map parent data
        axios({
          method: 'GET',
          // url: `https://samruddhi_api.latlong.ai/area_information?order=${sharedAreaOrder}&search=parent&area_id=${sharedAreaId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`,
          url: `https://samruddhi_api_v2.latlong.in/samruddhi_v1/area_information?search=parent&area_id=${sharedAreaId}`,
          mode: 'no-cors'
        }).then((response) => {
          setShowLoader(false);
          // console.log(response.data.data, "map_parent_data")
          setParentData(response.data.data);
          // map.current.fitBounds([
          //   response.data.data[0].properties.centroid.geometry.coordinates,
          //   response.data.data[0].properties.centroid.geometry.coordinates
          // ], {padding: {top: 100, bottom:100, left: 100, right: 100}, maxZoom: 7});
        }).catch((error) => {
          // console.log(error);
        })



        //clicked feature
        // if(clickedFeature != null){      
        //   console.log(clickedFeature,'clicked feature');
        //   axios({
        //     method: 'GET',
        //     url: `http://34.204.182.12:8011/area_information?order=8&search=child&area_id=${clickedFeature}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`,
        //     mode: 'no-cors'
        //   }).then( (response) => {
        //     setShowLoader(false);
        //     console.log(response.data.data, "map_data clicked")
        //     setChildData(response.data.data);
        //     map.current.fitBounds([
        //       response.data.data[0].properties.centroid.geometry.coordinates,
        //       response.data.data[0].properties.centroid.geometry.coordinates
        //     ], {padding: {top: 100, bottom:100, left: 100, right: 100}, maxZoom: 7});
        //   }).catch( (error) => {
        //     console.log(error);
        //   })
        // }

        //scheme info data
        // axios({
        //   method: 'GET',
        //   url: `https://samruddhi_api.latlong.ai/demographic?area_id=${sharedAreaId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`
        // }).then((response) => {
        //   setSchemeLoading(false); 
        //   setSchemeData(response.data.data);
        //   console.log(response.data.data,'scheme data');
        // }).catch((error) => {
        //   console.log(error);
        // });

        //manifest api
        // axios({
        //   method: 'GET',
        //   url: `https://samruddhi_api.latlong.ai/manifesto?area_id=${sharedAreaId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`
        // }).then((response) => {          
        //   setManifestoData(response.data.data);
        //   console.log(response.data.data,'manifest data');
        // }).catch((error) => {
        //   console.log(error);
        // });

      } else {
        setAlertOpen(true);
      }
    }
  }, [value, clickedFeature, sharedAreaId]);

  useEffect(() => {
    setValue({ area_id: sharedAreaId, area_name: 'shivamoga', aa_order: 38 })
    setShareData({ area_id: sharedAreaId, area_name: 'shivamoga', aa_order: 38 })
  }, [sharedAreaId])


  const autocomplete = (newValue) => {

    if (newValue.length > 2) {
      //autocomplete api
      axios({
        method: 'GET',
        url: `https://samruddhi_api.latlong.ai/autocomplete?name=${newValue}&limit=10&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJRCI6Ijc3ZGUxYTQwLTljY2QtNGE4Ny04NWMyLTJjMjRkYzU1ZjUwOCIsIkJ1bml0SUQiOjI0LCJUaW1lU3RhbXAiOiIyMDIyLTEyLTE0IDA5OjU5OjAzIiwiZXhwIjoxNzAzNDExOTQzfQ.o85ShHb0RVKvN99cqqLxKpkFgGTBOjRzxgmMCBiKqos`
      }).then((response) => {
        setAutocompleteInputs(response.data.data);
        // console.log(response.data.data);
      }).catch((error) => {
        // console.log(error);
      })

    }
  }

  // console.log(sharedAreaId, "hellooo")

  return (
    <>
      {false &&
        <>
          <Autocomplete
            // disabled={disabledValue}
            blurOnSelect
            className={`autocomplete-input ${initial ? '' : ''}`}
            value={value}
            onInputChange={(e, val, reason) => {
              autocomplete(val);
            }}
            onChange={(event, newValue) => {

              if (typeof newValue === 'string') {
                setValue({
                  area_name: newValue,
                });
                setSharedAreaId(newValue.area_id)
                setSharedAreaOrder(newValue.aa_order)
                setShareData([newValue.area_name, newValue.area_id, newValue.aa_order]);
                setOpen(false)
              } else {
                setValue(newValue);
                setSharedAreaId(newValue.area_id)
                setSharedAreaOrder(newValue.aa_order)
                setShareData([newValue.area_name, newValue.area_id, newValue.aa_order]);
                setDisabledValue(true);
                setOpen(false)
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.area_name);
              // if (inputValue !== '' && !isExisting) {
              //   filtered.push({
              //     inputValue,
              //     area_name: `Add "${inputValue}"`,
              //   });
              // }

              return filtered;
            }}
            // blurOnSelect
            // selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={autocompleteInputs}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.area_name;
            }}
            // renderOption={(props, option) => <li {...props}>{option.area_name}</li>}
            renderOption={(props, option, index) => {
              const key = `listItem-${index}-${option.area_id}`
              var place
              {
                var index = aa_order_names.find(item => item.aa_order === option.aa_order)
                place = index.name
              }
              return (
                <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} {...props} key={key} > {option.area_name} <i style={{ textAlign: 'end', fontSize: '10px' }}> {place} </i></li>
              )
            }}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params,) => (
              <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '4px', borderRadius: '4px' }}>
                <LocationSearchingIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField {...params} id="search_input" label="Search locations" variant='standard'
                  variant="filled" value="bengaluru"
                />
              </Box>
            )}
          />
          <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert severity="info" action={action}>Please select from dropdown options.<h1>{params.aa_id}</h1></Alert>
          </Snackbar>
        </>
      }
    </>
  );
}
const searchDistrict = [
  // { area_name: "Dakshina Kannada", area_id: '10000194' },
  // { area_name: 'Belagavi', area_id: '10000171' },
];
