import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import PersonalitiesTabs from './PersonalitiesTabs';
const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  let setOpen = props.setOpen; 
  let open = props.open;
  const [numberOf,setNumberOf] = useState(0);
  let videos = props.videos;
  let shareData = props.shareData;
  let beneficiaryData = props.beneficiaryData;
  let schemeData = props.schemeData;
  let manifestoData = props.manifestoData;
  let videosLoading = props.videosLoading;
  let beneficiaryLoading = props.beneficiaryLoading;
  let schemeLoading = props.schemeLoading;
  let pageNumber = props.pageNumber;
  useEffect(()=>{
    if(videos){
      setNumberOf(videos.length);
    }    
  },[videos])
    
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    if(!newOpen){ 
      //reverts back to first tab     
      document.getElementById('simple-tab-0').click();
    }
  };

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;
  
  return (
    <Root style={{zIndex: 2}}>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <Box sx={{ textAlign: 'center', pt: 1, zIndex:3 }}>
        <Button onClick={toggleDrawer(true)}>Open</Button>
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        hysteresis={0}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        style={{overflow: 'auto', height: '100px'}}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: 'white', backgroundColor: '#4381c3', borderRadius: '8px 8px 0 0', fontWeight: 'bold'}}>
            {/* {numberOf} videos found. */} Yojana
          </Typography>
        </StyledBox>
        <PersonalitiesTabs videos={videos} beneficiaryData={beneficiaryData} schemeData={schemeData} manifestoData={manifestoData} shareData={shareData} videosLoading={videosLoading} beneficiaryLoading={beneficiaryLoading} schemeLoading={schemeLoading} pageNumber={pageNumber} />        
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;