
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// import CarousalWP from './CarousalWP';
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import { browserName, isIOS, isMobile } from "react-device-detect";
import html2canvas from "html2canvas";
import HomeIcon from '@mui/icons-material/Home';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Fab from '@mui/material/Fab';
import BarChartIcon from '@mui/icons-material/BarChart';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import WpApexChart from './WpApexChart.js'

import WPSocialWelfare1 from './../../assets/wp_infographics/Social Welfare-1.png';

import WpInfraN from './../../assets/wp_infographics/Infrastructure -1.png';

import WpUpa1 from './../../assets/wp_infographics/Mismanagement_1.png';
import WpUpa2 from './../../assets/wp_infographics/Mismanagement_2.png';
import WpUpa3 from './../../assets/wp_infographics/Mismanagement_3.png';
import WpUpa4 from './../../assets/wp_infographics/Mismanagement_4a.png';
import WpUpa5 from './../../assets/wp_infographics/Mismanagement_4b.png';
import WpUpa6 from './../../assets/wp_infographics/Mismanagement_5.png';
import WpUpa7 from './../../assets/wp_infographics/Mismanagement_6.png';
import WpUpa8 from './../../assets/wp_infographics/Mismanagement_7.png';

import WpEco1 from './../../assets/wp_infographics/Economy -1.png';
import WpEco2 from './../../assets/wp_infographics/Economy -2.png';
import WpEco3 from './../../assets/wp_infographics/Economy -3.png';
import WpEco4 from './../../assets/wp_infographics/Economy -4.png';
import WpEco5 from './../../assets/wp_infographics/Economy -5.png';
import WpEco6 from './../../assets/wp_infographics/Economy -6.png';
import WpEco8 from './../../assets/wp_infographics/Economy -7.png';
import WpEco9 from './../../assets/wp_infographics/Economy -8.png';
import WpEco10 from './../../assets/wp_infographics/Economy -9.png';
import WpEco11 from './../../assets/wp_infographics/Economy -10.png';
import WpEco12 from './../../assets/wp_infographics/Economy -11.png';
import WpEco13 from './../../assets/wp_infographics/Economy -12.png';

import WpBg from './../../assets/wp_infographics/wp_bg.png';
import Then from './../../assets/wp_infographics/then.png';
import BJPLogoNew from "./../../assets/bjp_logo_new.png";

import WpData from "./WpData.json";



const InfogrpahicsWhitePaper = (props) => {

  let setIsModalOpen = props.setIsModalOpen;
  let setPdfShow = props.setPdfShow;
  let setChartPdfShow = props.setChartPdfShow;
  let setWpLanguage = props.setWpLanguage;
  let wpLanguage = props.wpLanguage;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isPaginationVisible, setIsPaginationVisible] = useState(true);
  const [isCategorySelected, setIsCategorySelected] = useState(null);


  const WPCategories = [
    { index: 1, text: 'Mismanagement of UPA' },
    { index: 2, text: 'Economy' },
    { index: 3, text: 'Infrastructure' },
    { index: 4, text: 'Social Welfare' },
    { index: 5, text: 'Enterprises' },
    { index: 6, text: 'Ease of Doing Bussiness' },
    { index: 7, text: 'Digital India' },
    { index: 8, text: 'Education' },
  ];

  const handleSelectedOptionsChange = (event, value) => {
    // console.log(value, ' category')
    setIsCategorySelected(value);
    // setSelectedOptions(null)
    setSelectedOptions(value);

  };


  const filteredData = selectedOptions ? WpData[wpLanguage].filter(item => item.Category === selectedOptions.text) : [];


  // console.log(selectedOptions, 'selectedOptions')

  const latestImageData = useRef(null);

  const loadData = useCallback(async () => {
    try {
      if (isMobile && browserName === "Chrome" && isIOS) {
        const input = document.getElementById("wp_infographics_container");
        const canvas = await html2canvas(input);
        const imgData = canvas.toDataURL("image/jpeg");
        const blob = await fetch(imgData).then((res) => res.blob());
        const file = new File([blob], "wp_infographics.jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        const filesArray = [file];
        latestImageData.current = filesArray;
      }
    } catch (error) {
      // alert(error.message);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadData();
  });

  const handleShareClick = async () => {
    setIsPaginationVisible(false);
    try {
      if (latestImageData.current) {
        const shareData = {
          files: latestImageData.current,
        };
        if (navigator.share) {
          await navigator.share(shareData);
          // console.log("Shared successfully");
        } else {
          console.error("Web Share API not supported");
        }
      } else {
        console.error("No image data to share");
      }
    } catch (err) {
      // alert(err.message);
    }
  };

  // console.log(wpLanguage, "wpLanguage")


  const webShareWP = () => {
    // setShowLoader(true);
    handleShareClick();
    try {
      const input = document.getElementById(`wp_infographics_container`);
      // const input = document.getElementById(`image_${selectedOptions && selectedOptions.index}_${activeSlideIndex}`);
      html2canvas(input).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        const blob = await fetch(imgData).then((res) => res.blob());
        const file = new File([blob], "wp_infographics.jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        const filesArray = [file];
        // setShowLoader(false);
        if (navigator.share && browserName === "Chrome" && isIOS) {
          // if (clickedShare === false) {
          //   try {
          //     setClickedShare(true);
          //   } catch (error) { }
          // }
        } else {
          const shareData = {
            files: filesArray,
          };
          if (navigator.share) {
            navigator
              .share(shareData)
              .then(() => {
                console.log("Shared successfully");

              })
              .catch((error) => {
                console.error("Error sharing:", error);
              });
          } else {
            console.error("Web Share API not supported");
          }
          // setIsPaginationVisible(true);
        }
      });
    } catch (error) {
      console.error("Error sharing:", error);
      // alert(error.message);
    }
  }

  useEffect(() => {
    if (!isPaginationVisible) {
      const timer = setTimeout(() => {
        setIsPaginationVisible(true);
      }, 5000);
    }
  }, [isPaginationVisible])

  const getImageByCategory = (category) => {
    switch (category) {
      case 1:
        return { image1: WpUpa1, image2: WpUpa2, image3: WpUpa3, image4: WpUpa4, image5: WpUpa5, image6: WpUpa6, image7: WpUpa7, image8: WpUpa8, }; //mismanagement of upa  
      case 2:
        return {
          image1: WpEco1, image2: WpEco2, image3: WpEco3, image4: WpEco4, image5: WpEco5, image6: WpEco6, image8: WpEco8, image9: WpEco9, image10: WpEco10, image11: WpEco11, image12: WpEco12, image13: WpEco13,
        }; //economy
      case 3:
        return { image1: WpInfraN }; //Infrastructure
      case 4:
        return { image1: WPSocialWelfare1, }; //social welfare

      default:
        return null;
    }
  };

  const renderImages = () => {
    // Check if selectedOptions is not null and is an object
    if (selectedOptions && typeof selectedOptions === 'object') {
      const category = selectedOptions.index;
      const images = getImageByCategory(category);
      if (images) {
        return Object.values(images).map((image, index) => (
          <div className="swiper-slide" key={`${category}_${index}`} style={{ overflow: 'auto', display: 'flex', }}>
            <img src={image} alt={`Image ${category}_${index}`} id={`image_${category}_${index}`} style={{ maxWidth: '300px', alignSelf: 'center' }} />
          </div>
        ));
      } else {
        console.error('Images not found for the selected category');
        return null;
      }
    } else {
      console.error('selectedOptions is not an object or is null');
      return null;
    }
  };

  useEffect(() => {
    reloadSwipper();
  }, [selectedOptions])

  const swiper = useRef(null);

  const reloadSwipper = () => {

    const carousel = new Swiper(swiper.current, {
      // Optional parameters
      direction: 'horizontal',
      loop: true,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      allowTouchMove: false,

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      on: {
        slideChange: function () {
          const activeIndex = this.activeIndex;
          setActiveSlideIndex(activeIndex);
          // console.log('Active slide index:', activeIndex);
        },
      },
    });

    return () => {
      carousel.destroy();
    }

  }

  // const swiper = useRef(new Swiper('.swiper', {
  //   // Optional parameters
  //   direction: 'horizontal',
  //   loop: true,

  //   // If we need pagination
  //   pagination: {
  //     el: '.swiper-pagination',
  //   },

  //   // Navigation arrows
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },

  //   allowTouchMove: false,

  //   // And if we need scrollbar
  //   scrollbar: {
  //     el: '.swiper-scrollbar',
  //   },
  //   on: {
  //     slideChange: function () {
  //       const activeIndex = this.activeIndex;
  //       setActiveSlideIndex(activeIndex);
  //       console.log('Active slide index:', activeIndex);
  //     },
  //   },
  // })
  // );


  const togglePdfShow = () => {
    setIsModalOpen(false);
    setPdfShow(prevState => !prevState);
  };

  const toggleChartPdfShow = () => {
    setIsModalOpen(false);
    setChartPdfShow(prevState => !prevState);
  };

  console.log(filteredData.SerialNumber, "filteredData")

  return (
    <>

      <Autocomplete
        multiple={false}
        id="tags-outlined"
        size="medium"
        style={{ minWidth: '240px', maxWidth: '280px', marginBottom: '16px' }}
        options={WPCategories}
        getOptionLabel={(option) => option.text}
        // defaultValue={[WPCategories[4]]}
        onChange={handleSelectedOptionsChange}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.index === value.index && option.text === value.text}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Categories"
            placeholder="search"
          />
        )}
      />

      <Divider variant="middle" />

      <Box>
        <Paper style={{ width: '300px', height: 'auto', minHeight: '420px' }} elevation={3} id="wp_infographics_container">

          {/* <CarousalWP selectedOptions={selectedOptions}></CarousalWP> */}

          {
            isCategorySelected ?
              <div class="swiper" ref={swiper}>
                <div class="swiper-wrapper">





                  {filteredData.map((item, index) => (

                    <div className="swiper-slide" key={item.SerialNumber} id={item.SerialNumber}>

                      <div style={{ width: 'inherit', height: 'inherit', backgroundImage: `url(${WpBg})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', }}>
                        <div style={{ marginTop: '70px' }}>
                          <h3 style={{ textAlign: 'center', margin: '0', fontSize: (wpLanguage !== 'english' && (item.Heading && typeof item.Heading === 'string' && item.Narrative.toString().length > 20)) ? '10px' : '16px', }}>{item.Heading}</h3>
                          <div
                            style={{
                              background: 'rgba(0,255,0,0.2', margin: '8px 32px', textAlign: 'center',
                              fontSize: (wpLanguage !== 'english' && (item.Narrative && typeof item.Narrative === 'string' && item.Narrative.toString().length > 45)) ? '10px' : '16px',
                            }}
                          >
                            {item.Narrative}
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', left: '12px' }}>
                              <img src={Then} alt="Congress logo" style={{ maxWidth: '42px', transform: `scale(1)` }} />
                              <small style={{ fontSize: wpLanguage !== 'english' && '10px' }}>{item.Then}</small>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <img src={BJPLogoNew} alt="BJP logo" style={{ maxWidth: '40px', transform: `scale(1)` }} />
                              <small style={{ fontSize: wpLanguage !== 'english' && '10px' }}>{item.Now}</small>
                            </div>
                          </div>

                          <div style={{ display: 'flex', justifyContent: 'center', margin: '0 18px' }}>
                            <WpApexChart data={item} inverted={item.Heading}></WpApexChart>
                          </div>
                          <small style={{ position: 'relative', bottom: '20px', fontSize: '10px', width: 'inherit', display: 'flex', justifyContent: 'center' }}>
                            {item.PS && item.PS}
                          </small>
                        </div>
                      </div>
                    </div>

                  ))}


                  {renderImages()}

                </div>

                {/* <div className={`swiper-pagination ${isPaginationVisible ? 'hide_pagination' : 'hide_pagination'}`}></div>
                <div className={`swiper-button-prev ${isPaginationVisible ? 'show_pagination' : 'hide_pagination'}`}></div>
                <div className={`swiper-button-next ${isPaginationVisible ? 'show_pagination' : 'hide_pagination'}`}></div>
                <div className={`swiper-scrollbar ${isPaginationVisible ? 'show_pagination' : 'hide_pagination'}`}></div> */}

                <div className={`swiper-pagination ${isPaginationVisible ? 'hide_pagination' : 'hide_pagination'}`} data-html2canvas-ignore="true"></div>
                <div className={`swiper-button-prev ${isPaginationVisible ? 'show_pagination' : 'hide_pagination'}`} data-html2canvas-ignore="true"></div>
                <div className={`swiper-button-next ${isPaginationVisible ? 'show_pagination' : 'hide_pagination'}`} data-html2canvas-ignore="true"></div>
                <div className={`swiper-scrollbar ${isPaginationVisible ? 'show_pagination' : 'hide_pagination'}`} data-html2canvas-ignore="true"></div>

              </div>
              :
              <div style={{ height: '420px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ textAlign: 'center' }}>Select category <br /> to view infographics here</p>
              </div>
          }

          {/* <p> image_{selectedOptions && selectedOptions.index}_{activeSlideIndex}</p> */}

        </Paper>
      </Box>

      <div style={{ width: '300px', height: 'auto', display: 'flex', margin: '12px', justifyContent: 'space-evenly', zIndex: '5' }}>
        <Fab variant="extended" size="medium" color="primary" onClick={() => { setIsModalOpen(true) }}>
          <ArrowBackIcon color="white" size={32} round={true} ></ArrowBackIcon>
        </Fab>
        <Fab variant="extended" size="medium" color="primary" onClick={() => togglePdfShow()}>
          <PictureAsPdfIcon color="white" size={32} round={true}></PictureAsPdfIcon>
        </Fab>
        <Fab variant="extended" size="medium" color="primary" onClick={() => toggleChartPdfShow()}>
          <BarChartIcon color="white" size={32} round={true}></BarChartIcon>
        </Fab>
        {
          isCategorySelected &&
          <Fab variant="extended" size="medium" color="primary" onClick={() => webShareWP()}>
            <ShareIcon color="white" size={32} round={true} />
          </Fab>
        }

      </div>

    </>

  );
}

export default InfogrpahicsWhitePaper