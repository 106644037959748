import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import latlongLogo from '../assets/Latlong-Logo.png';
import FormControl from '@mui/material/FormControl';
import './global.css';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Fab from '@mui/material/Fab';
import WhitePaperPdf from './../components/Pdf/WhitePaper';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

const Header = ({ setLanguageChange, languageChange, setIsModalOpen, isModalOpen, isNativeLanguage, nativeScript, nativeLanguage, setLanguage, language, samruddhiV2, setPdfShow, pdfShow, setWpLanguage, wpLanguage, dashboard, setDashboard }) => {

  const location = useLocation();

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    setLanguageChange(newLanguage);
  };


  const changeWpLanguage = (newLanguage) => {
    setWpLanguage(newLanguage);
  };

  const goToHome = () => {
    window.location.href = '/';
  };

  const goToLatlong = () => {
    window.open("https://www.latlong.ai/", "_blank");
  }

  const togglePdfShow = () => {
    // setIsModalOpen(true);
    setPdfShow(prevState => !prevState);
  };

  return (
    <AppBar className='header' position="sticky" style={{ top: 0, backgroundColor: '#d3d3d3', color: 'black', zIndex: 1000 }}>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" component="div" onClick={goToHome} style={{ fontWeight: 'bold', color: '#4381c3', fontFamily: "'Itim', sans-serif", cursor: 'pointer' }}>
            White Paper
          </Typography>
        </div>
        {location.pathname !== '/sources' &&

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(!isModalOpen && (samruddhiV2 || dashboard)) ?
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ margin: 0 }}>
                <Select
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                  sx={{ padding: 0 }}
                  IconComponent={() => <LanguageIcon color="primary" />}
                >
                  <MenuItem value="english">English</MenuItem>
                  <MenuItem value="hindi">हिंदी</MenuItem>
                  {nativeScript && !isNativeLanguage && nativeLanguage.toLowerCase() != 'english' && <MenuItem value={nativeLanguage && nativeLanguage.toLowerCase()}>{nativeScript && nativeScript}</MenuItem>}
                </Select>
              </FormControl>
              :
              <FormControl sx={{ m: 1, minWidth: 120, }} size="small" style={{ margin: 0 }}>
                <Select
                  value={wpLanguage}
                  onChange={(e) => changeWpLanguage(e.target.value)}
                  sx={{ padding: 0 }}
                  IconComponent={() => <LanguageIcon color="primary" />}
                >
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="english">English</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="hindi">हिंदी</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="kannada">ಕನ್ನಡ</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="tamil">தமிழ்</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="telugu">తెలుగు</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="malyalam">മലയാളം</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="marathi">मराठी</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="odia">ଓଡିଆ</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="bengali">বাংলা</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="urdu">اردو</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="assamese">অসমীয়া</MenuItem>
                  <MenuItem style={{ borderBottom: '1px solid #d3d3d3' }} value="gujarati">ગુજરાતી</MenuItem>
                </Select>
              </FormControl>
            }
            {/* <img src={latlongLogo} alt="Logo" onClick={goToLatlong} style={{ marginLeft: 10, height: '30px', width: '100px', cursor: 'pointer' }} /> */}
            {/* {
            !isModalOpen &&
            <div style={{ position: 'fixed', top: '60px', right: '6px', zIndex: '10001' }}>
              <Fab size="small" color="secondary" aria-label="add" onClick={() => togglePdfShow()} style={{ cursor: 'pointer' }}>
                {!pdfShow ?
                  <PictureAsPdfIcon></PictureAsPdfIcon>
                  :
                  <CloseIcon></CloseIcon>
                }
              </Fab>
            </div>
          } */}
          </div>
        }

      </Toolbar>
    </AppBar >
  );
};

export default Header;
