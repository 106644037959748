import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import latlongLogo from '../../../assets/Latlong-Logo.png'
import FormControl from '@mui/material/FormControl';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { LoginOutlined, Logout } from '@mui/icons-material';
import { Button } from '@mui/material';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from 'react-router';

const Header = () => {

    const session = useIsAuthenticated()
    const isAuthenticated = session
    const signOut = useSignOut()
    const navigate = useNavigate()

    const goToHome = () => {
        window.location.href = '/dashboard';
    };

    const goToLatlong = () => {
        window.open("https://www.latlong.ai/", "_blank");
    }

    const signOutUser = () => {
        signOut()
        navigate('/dashboard/login', { replace: true })
    }

    return (
        <AppBar className='header' position="sticky" style={{ top: 0, backgroundColor: '#d3d3d3', color: 'black', zIndex: 1000 }}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" component="div" onClick={goToHome} style={{ fontWeight: 'bold', color: '#4381c3', fontFamily: "'Itim', sans-serif", cursor: 'pointer' }}>
                        White Paper
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                    <img src={latlongLogo} alt="Logo" onClick={goToLatlong} style={{ marginLeft: 10, height: '30px', width: '100px', cursor: 'pointer' }} />
                    {
                        isAuthenticated ?
                            <Button style={{ display: 'flex', alignItems: 'center', gap: '5px' }} onClick={signOutUser}>
                                <Logout />
                                <span>Sign Out</span>
                            </Button> :
                            <></>
                    }
                </div>
            </Toolbar>
        </AppBar >
    );
};

export default Header;
