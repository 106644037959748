import * as React from 'react';
import { Badge, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormHelperText, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Stack, TextField } from '@mui/material';
import { CheckCircleOutline, CloudUploadOutlined, Delete, Edit, History, UploadFileOutlined } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Loader from '../subcomponents/DashboardLoader';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import { toast } from 'react-toastify';

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, Controller } from "react-hook-form"
import { z } from "zod"
import MUIDataTable from "mui-datatables";
import moment from 'moment/moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'video_link', headerName: 'Video Link', width: 400 },
  { field: 'scheme_ids', headerName: 'Tagged Schemes', width: 400 },
  { field: 'pc_ids', headerName: 'Parliamentary Constituencies', width: 400 },
  { field: 'video_category', headerName: 'Category', width: 100 }
]

export default function DataTable() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false) };
  const authToken = useAuthHeader()

  const [uploadedVideos, setUploadedVideos] = React.useState()
  const [schemes, setSchemes] = React.useState([]);
  const [pcs, setPcs] = React.useState([]);
  const [vips, setVips] = React.useState([]);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [videoDetails, setVideoDetails] = React.useState({ video_link: '', scheme_ids: [], pc_ids: [], vip: [], video_category: '' })

  const formSchema = z.object({
    id: z.any(),
    video_link: z.string().min(2, {
      message: "Required.",
    }),
    scheme_ids: z.array(z.number()).nonempty({ message: 'Required' }),
    pc_ids: z.array(z.number()).nonempty({ message: 'Required' }),
    vips: z.array(z.number()).nonempty({ message: 'Required' }),
    category: z.string({ required_error: 'Required' }).min(1, {
      message: 'Required'
    })
  })

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  async function getInitialFormData() {
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/uploader_initial_values`, {
        headers: {
          'X-Authorization-Token': authToken
        }
      })
      let data = response.data
      // console.log(data)
      let pc_data = data.pcs
      let scheme_data = data.schemes
      let vip_data = data.vips
      setPcs(pc_data)
      setSchemes(scheme_data)
      setVips(vip_data)
      // toast.success('Success', {position: 'top-center'})
    } catch (error) {
      toast.error('Oops...Something went wrong !', { position: 'top-center' })
      console.log(error.message)
    }
  }

  React.useEffect(() => {
    getInitialFormData()
    fetchUploadedVideos()
  }, [])

  const { handleSubmit, control, formState: { errors }, reset } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      video_link: '',
      scheme_ids: [],
      pc_ids: [],
      vips: [],
      category: ''
    }
  });

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/upload_video`, data, {
        headers: {
          'X-Authorization-Token': authToken
        }
      })
      toast.success('Video Uploaded Successfully.', { position: 'top-center' })
      handleClose()
      reset()
      fetchUploadedVideos()
    } catch (error) {
      console.log(error.message)
      toast.error('Something went wrong!', { position: 'top-center' })
      setLoading(false)
    }
  };


  const fetchUploadedVideos = async () => {
    setLoading(true)
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/get_uploaded_videos`, {
        headers: {
          'X-Authorization-Token': authToken
        }
      })
      setUploadedVideos(response.data.videos)
      setLoading(false)
    } catch (error) {
      toast.error('Something went wrong', { position: 'top-center' })
      setLoading(false)
    }
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "video_link",
      label: "VIDEO LINK",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <a href={value} target='_blank'>{value}</a>
            </div>
          );
        },
      }
    },
    {
      name: "scheme_ids",
      label: "Schemes",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value.map(element => schemes.find(option => option.id == element)?.name).join(", ")}
            </div>
          );
        },
      }
    },
    {
      name: "pc_ids",
      label: "Parliamentary Constituencies",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value && value.map(element => pcs.find(option => option.id == element)?.name).join(", ")}
            </div>
          );
        },
      }
    },
    {
      name: "vip",
      label: "VIPs",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value && value.map(element => vips.find(option => option.id == element)?.name).join(", ")}
            </div>
          );
        },
      }
    },
    {
      name: "video_category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value && (value).toUpperCase()}
            </div>
          );
        },
      }
    },
    {
      name: 'created_at', label: 'Added On', options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {moment(value).fromNow()}
            </div>
          );
        },
      }
    },
    {
      name: 'updated_at', label: 'Updated At', options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {moment(value).fromNow()}
            </div>
          );
        },
      }
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value == 'Approved' ?
                <Chip sx={{ background: '#1aa950', color: 'white' }} icon={<CheckCircleOutline color='white' />} size='small' label={value}></Chip> :
                <Chip sx={{ background: '#FAD5A5' }} icon={<History />} size='small' label={value}></Chip>}
            </div>
          );
        },
      }
    },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta)
          if (tableMeta.rowData[8] == 'Waiting For Approval') {
            return (
              <IconButton color='info' sx={{
                background: '#fff', border: '1px solid #eeeeee', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Customize the shadow as needed
                '&:hover': {
                  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.4)',
                }
              }}
                onClick={async () => {
                  setLoading(true)
                  setVideoDetails({ video_link: '', scheme_ids: [], pc_ids: [], vip: [], video_category: '' })
                  try {
                    setEditDialogOpen(true)
                    let response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/get_video_details?id=${tableMeta.rowData[0]}`, {
                      headers: {
                        'X-Authorization-Token': authToken
                      }
                    })
                    let data = response.data.video_details
                    if (response.data.status == '200') {
                      setVideoDetails(data)
                      setLoading(false)
                    }
                  } catch (error) {
                    setLoading(false)
                    toast.error('Something went wrong!', { position: 'top-center' })
                  }
                }}
              >
                <Edit />
              </IconButton>
            );
          } else {
            return (
              <IconButton disabled color='secondary' sx={{
                background: '#fff', border: '1px solid #eeeeee'
              }}>
                <Edit />
              </IconButton>
            )
          }
        },
      }
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] == 'Waiting For Approval') {
            return (
              <IconButton color='error'
                onClick={async () => {
                  const result = window.confirm('Are you sure you want to delete?');
                  if (result) {
                    setLoading(true)
                    try {
                      let response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/delete_video?id=${tableMeta.rowData[0]}`, {
                        headers: {
                          'X-Authorization-Token': authToken
                        }
                      })
                      let data = response.data
                      if (data.status == '200') {
                        setLoading(false)
                        toast.success('Upload deleted successfully.')
                      }
                      fetchUploadedVideos()
                    } catch (error) {
                      setLoading(false)
                      toast.error('Something went wrong!', { position: 'top-center' })
                    }
                  } else {
                    console.log('Cancel')
                  }
                }}
                sx={{
                  background: '#FFEDD8', border: '1px solid #eeeeee', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Customize the shadow as needed
                  '&:hover': {
                    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.4)',
                  },
                }}>
                <Delete />
              </IconButton>
            );
          } else {
            return (
              <IconButton color='secondary' disabled sx={{
                background: '#fff', border: '1px solid #eeeeee'
              }}>
                <Delete />
              </IconButton>
            )
          }
        },
      }
    },
    {
      name: 'approve', label: 'Approve', options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] == 'Waiting For Approval') {
            return (
              <Button
                onClick={async () => {
                  const result = window.confirm('Are you sure you want to proceed?');
                  if (result) {
                    setLoading(true)
                    try {
                      let response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/approve_video?id=${tableMeta.rowData[0]}`, {
                        headers: {
                          'X-Authorization-Token': authToken
                        }
                      })
                      let data = response.data
                      if (data.status == '200') {
                        setLoading(false)
                        toast.success('Approved successfully.')
                      }
                      fetchUploadedVideos()
                    } catch (error) {
                      setLoading(false)
                      toast.error('Something went wrong!', { position: 'top-center' })
                    }
                  } else {
                    // User clicked "Cancel" (false) or closed the dialog
                    // Handle cancellation or do nothing
                  }
                }}
                variant='contained' color='success' > Approve</Button >
            );
          } else {
            return (
              <Button variant='' disabled>Approved</Button>
            );
          }
        },
      }
    },
  ];

  return (
    <Box sx={{ m: 5 }}>
      <Loader open={loading} />
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <UploadFileOutlined style={{ color: '#4381c3' }} />
          <h3 style={{ color: '#4381c3' }}>Uploads</h3>
        </div>
        <Button variant='contained' onClick={handleOpen}><CloudUploadOutlined sx={{ mr: '10px' }} />Upload</Button>
      </div>
      <Divider sx={{ marginBottom: '11px', marginTop: '7px' }} />
      <EditUploadedModal pcs={pcs} schemes={schemes} editDialogOpen={editDialogOpen} setEditDialogOpen={setEditDialogOpen} MenuProps={MenuProps} fetchUploadedVideos={fetchUploadedVideos} vips={vips} videoDetails={videoDetails} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" component={'div'}>
          <Typography id="modal-modal-title" variant='h6' component={'h2'} sx={{ color: '#4381c3' }}>
            Upload New Video
          </Typography>
          <DialogContentText id="alert-dialog-description">
            upload and look forward for video approval.
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <Controller
            name="video_link"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Video Link"
                fullWidth
                sx={{ mt: 2 }}
                error={!!errors.video_link}
                helperText={errors.video_link?.message}
              />
            )}
          />
          <CustomMultiSelectController control={control} data={pcs} name={'pc_ids'} label={'Parliamentary Constituencies'} error={errors.pc_ids} />
          <CustomMultiSelectController control={control} data={schemes} name={'scheme_ids'} label={'Schemes'} error={errors.scheme_ids} />
          <CustomMultiSelectController control={control} data={vips} name={'vips'} label={'VIPs'} error={errors.vips} />
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <>
                <InputLabel id="category-label" sx={{ mt: 2 }}>Video category</InputLabel>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-multiple-checkbox-label"
                  value={field.value} // Change value to array of IDs
                  displayEmpty={true}
                  fullWidth
                  sx={{ minHeight: 50, mt: 1 }}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                >
                  <MenuItem key={'leaders'} value={'leaders'}>
                    <ListItemText primary={'Leaders'} />
                  </MenuItem>
                  <MenuItem key={'beneficiaries'} value={'beneficiaries'}>
                    <ListItemText primary={'Beneficiaries'} />
                  </MenuItem>
                </Select>
                {!!errors.category && <FormHelperText sx={{ color: 'red' }} error={true}>{errors.category?.message}</FormHelperText>}
              </>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleClose()
            reset()
          }}>Cancel Upload</Button>
          <Button type='submit' variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <MUIDataTable
        title={""}
        data={uploadedVideos}
        columns={columns}
        options={{
          filter: true,
          download: false,
          print: true,
          rowHover: true,
          viewColumns: false,
          pagination: true,
          selectableRows: 'none',
        }}
      />
    </Box>
  );
}

function CustomMultiSelectController({ control, name, data, label, error }) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <InputLabel id="pc-label" sx={{ mt: 2 }} error={error}>{label}</InputLabel>
            <Select
              style={{ width: "100%" }}
              labelId="demo-multiple-checkbox-label"
              multiple
              value={field.value}
              displayEmpty={true}
              fullWidth
              sx={{ minHeight: 50, mt: 1 }}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                return selected.map(id => data.find(option => option.id == id)?.name).join(", ") || " Select some options";
              }}
              onChange={(e) => {
                const selectedOptions = e.target.value.map(id => data.find(option => option.id == id))
                field.onChange(e.target.value);
              }}
              error={error ? true : false}
            >
              {data && data.length > 0 && data.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox checked={field.value.map(opt => opt).indexOf(option.id) >= 0} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
            {!!error && <FormHelperText sx={{ color: 'red' }} error={true}>{error.message}</FormHelperText>}
          </>

        )}
      />
    </>
  )
}

function EditUploadedModal({ pcs, vips, schemes, fetchUploadedVideos, MenuProps, editDialogOpen, setEditDialogOpen, videoDetails }) {
  const [loading, setLoading] = React.useState(false)
  const handleOpen = () => setEditDialogOpen(true);
  const handleClose = () => setEditDialogOpen(false);
  const authToken = useAuthHeader()
  const formSchema = z.object({
    id: z.any(),
    video_link: z.string().min(2, {
      message: "Required.",
    }),
    scheme_ids: z.array(z.number()).nonempty({ message: 'Required' }),
    pc_ids: z.array(z.number()).nonempty({ message: 'Required' }),
    vips: z.array(z.number()).nonempty({ message: 'Required' }),
    category: z.string({ required_error: 'Required' }).min(1, {
      message: 'Required'
    })
  })

  const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: videoDetails.id,
      video_link: videoDetails.video_link,
      scheme_ids: videoDetails.scheme_ids,
      pc_ids: videoDetails.pc_ids,
      vips: videoDetails.vip,
      category: videoDetails.video_category
    }
  });

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/update_video`, data, {
        headers: {
          'X-Authorization-Token': authToken
        }
      })
      toast.success('Video Updated Successfully.', { position: 'top-center' })
      handleClose()
      reset()
      fetchUploadedVideos()
    } catch (error) {
      console.log(error.message)
      toast.error('Something went wrong!', { position: 'top-center' })
      setLoading(false)
    }
  };

  React.useEffect(() => {
    setValue('id', videoDetails.id || '')
    setValue('video_link', videoDetails.video_link || '');
    setValue('scheme_ids', videoDetails.scheme_ids || []);
    setValue('pc_ids', videoDetails.pc_ids || []);
    setValue('vips', videoDetails.vip || []);
    setValue('category', videoDetails.video_category || '');
  }, [videoDetails, setValue]);

  return (
    <Dialog
      open={editDialogOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" component={'div'}>
        <Typography id="modal-modal-title" variant='h6' component={'h2'} sx={{ color: '#4381c3' }}>
          Edit Details
        </Typography>
        <DialogContentText id="alert-dialog-description">
          Make changes before video gets approval.
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Controller
          name="video_link"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Video Link"
              fullWidth
              sx={{ mt: 2 }}
              error={!!errors.video_link}
              helperText={errors.video_link?.message}
            />
          )}
        />
        <CustomMultiSelectController control={control} data={pcs} name={'pc_ids'} label={'Parliamentary Constituencies'} error={errors.pc_ids} />
        <CustomMultiSelectController control={control} data={schemes} name={'scheme_ids'} label={'Schemes'} error={errors.scheme_ids} />
        <CustomMultiSelectController control={control} data={vips} name={'vips'} label={'VIPs'} error={errors.vips} />
        <Controller
          control={control}
          name="category"
          render={({ field }) => (
            <>
              <InputLabel id="schemes-label" sx={{ mt: 2 }}>Video category</InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="demo-multiple-checkbox-label"
                value={field.value} // Change value to array of IDs
                displayEmpty={true}
                fullWidth
                sx={{ minHeight: 50, mt: 1 }}
                MenuProps={MenuProps}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={errors.category}
              >
                <MenuItem key={'leaders'} value={'leaders'}>
                  <ListItemText primary={'Leaders'} />
                </MenuItem>
                <MenuItem key={'beneficiaries'} value={'beneficiaries'}>
                  <ListItemText primary={'Beneficiaries'} />
                </MenuItem>
              </Select>
              {!!errors.category && <FormHelperText sx={{ color: 'red' }} error={true}>{errors.category?.message}</FormHelperText>}
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          handleClose()
          reset()
        }}>Cancel Upload</Button>
        <Button type='submit' variant='contained'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
