import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import YoutubeEmbed from './YoutubeEmbed';
import Accordion from './Accordian';
import SchemeData from './SchemeData';
import BeneficiaryData from './BeneficiaryData';
import AccordionBeneficiary from './AccordianBeneficiary';
import Skeleton from '@mui/material/Skeleton';
import ReactPaginate from 'react-paginate';
import Pagination from '@mui/material/Pagination';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './PaginationStyles.css';
import Manifesto from './Manifesto';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props, index) {
  const [value, setValue] = React.useState(0);
  let videos = props.videos;
  let shareData = props.shareData;
  let beneficiaryData = props.beneficiaryData;
  let schemeData = props.schemeData;
  let manifestoData = props.manifestoData;
  let videosLoading = props.videosLoading;
  let beneficiaryLoading = props.beneficiaryLoading;
  let schemeLoading = props.schemeLoading;
  let pageNumber = props.pageNumber;
  const tabHeadings = ['Leaders', 'Beneficiaries', 'Data', 'Manifesto']

  useEffect(() => {
    // const script = document.createElement("script");
    // script.async = true;
    // script.defer = true;
    // script.src = "//matomo.latlong.in/";
    // document.head.appendChild(script);
    // script.onload = () => {
    //   window._paq = window._paq || [];
    //   window._paq.push(['trackPageView']);
    //   window._paq.push(['enableLinkTracking']);
    //   window._paq.push(['setSiteId', '39']);
    // };
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // window._paq.push(['trackEvent', 'Tab Click', tabHeadings[newValue], newValue]);
  };

  // videos pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  var currentItems = [];
  var pageCount = 10;
  if (videos) {
    // console.log(videos, 'videos')
    currentItems = videos.slice(itemOffset, endOffset);
    pageCount = Math.ceil(videos.length / itemsPerPage);
  }
  const handlePageClick = (event) => {
    if (videos) {
      const newOffset = (event.selected * itemsPerPage) % videos.length;
      // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);
    }
  };
  // end

  // beneficiary pagination
  const [itemOffsetB, setItemOffsetB] = useState(0);
  const itemsPerPageB = 10;
  const endOffsetB = itemOffsetB + itemsPerPageB;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  var currentItemsB = [];
  var pageCountB = 10;
  if (beneficiaryData) {
    currentItemsB = beneficiaryData.slice(itemOffsetB, endOffsetB);
    pageCountB = Math.ceil(beneficiaryData.length / itemsPerPageB);
  }
  const handlePageClickB = (event) => {
    const newOffsetB = (event.selected * itemsPerPageB) % beneficiaryData.length;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffsetB(newOffsetB);
  };
  // end

  // console.log(currentItemsB, 'currentItemsB')

  // SchemeData pagination
  const [itemOffsetS, setItemOffsetS] = useState(0);
  const itemsPerPageS = 10;
  const endOffsetS = itemOffsetS + itemsPerPageS;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  var currentItemsS = [];
  var pageCountS = 10;
  if (schemeData) {
    currentItemsS = schemeData.slice(itemOffsetS, endOffsetS);
    pageCountS = Math.ceil(schemeData.length / itemsPerPageS);
  }
  const handlePageClickS = (event) => {
    const newOffsetS = (event.selected * itemsPerPageS) % schemeData.length;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffsetS(newOffsetS);
  };
  // end

  //manifesto data pagination
  const [itemOffsetM, setItemOffsetM] = useState(0);
  const itemsPerPageM = 10;
  const endOffsetM = itemOffsetM + itemsPerPageM;
  // console.log(`Loading items from ${itemOffsetM} to ${endOffsetM}`);
  var currentItemsM = [];
  var pageCountM = 10;
  if (manifestoData) {
    currentItemsM = manifestoData.slice(itemOffsetM, endOffsetM);
    pageCountM = Math.ceil(manifestoData.length / itemsPerPageM);
  }
  const handlePageClickM = (event) => {
    const newOffsetM = (event.selected * itemsPerPageM) % manifestoData.length;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffsetM}`);
    setItemOffsetM(newOffsetM);
  };
  //end


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="basic tabs example"
          sx={{
            '& .MuiTab-root': {
              '&.Mui-selected': {
                color: 'black',
                backgroundColor: 'rgb(255,146,72,0.6)',
              },
            }
          }}
        >
          <Tab label="Leaders" {...a11yProps(0)} />
          <Tab label="Beneficiaries" {...a11yProps(1)} />
          {/* <Tab style={{width: '40px'}} label="Data" {...a11yProps(2)} /> */}
          {/* <Tab style={{marginRight: '8px'}} label="Manifesto" {...a11yProps(3)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >
        <div className='drawer_tabs'>
          {
            videosLoading ? (
              <>
                {[...Array(10)].map((x, i) =>
                  <Stack key={i} direction="row" spacing={1} sx={{ width: "100%", marginTop: '12PX' }}>
                    <Skeleton variant="circular" width={60} height={60}></Skeleton>
                    <Stack sx={{ width: "70%" }}>
                      <Skeleton variant="rectangular" height={'60px'} width={'auto'} ></Skeleton>
                    </Stack>
                  </Stack>
                )}
              </>
            ) : (
              <>
                {(currentItems && currentItems.length > 0) ? currentItems.map((item) => (
                  <Accordion key={item.video_id} videos={item} shareData={shareData} />
                ))
                  :
                  <div style={{ height: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    No data available
                  </div>
                }
                {
                  // console.log("render finished")
                }
                <div className='navigation_container'>
                  <ReactPaginate
                    activeClassName={'item active '}
                    breakClassName={'item break-me '}
                    breakLabel={'...'}
                    containerClassName={'pagination'}
                    disabledClassName={'disabled-page'}
                    marginPagesDisplayed={1}
                    nextClassName={"item next "}
                    nextLabel={<ArrowForwardIosIcon style={{ fontSize: 18, width: 40 }} />}
                    pageClassName={'item pagination-page '}
                    previousClassName={"item previous"}
                    previousLabel={<ArrowBackIosIcon style={{ fontSize: 18, width: 40 }} />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={pageCount}
                    // forcePage={pageNumber}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            )
          }
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>

        <div className='drawer_tabs'>
          {
            beneficiaryLoading ? (
              <>
                {[...Array(10)].map((x, i) =>
                  <Stack key={i} direction="row" spacing={1} sx={{ width: "100%", marginTop: '12px' }}>
                    <Skeleton variant="circular" width={60} height={60}></Skeleton>
                    <Stack sx={{ width: "70%" }}>
                      <Skeleton variant="rectangular" height={'60px'} width={'auto'} ></Skeleton>
                    </Stack>
                  </Stack>
                )}
              </>
            ) : (
              <>
                {(currentItemsB && currentItemsB.length > 0) ? currentItemsB.map((item, index) => (
                  <AccordionBeneficiary key={index} beneficiaryData={item} shareData={shareData} />
                ))
                  :
                  <div style={{ height: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    No data available
                  </div>
                }
                <div className='navigation_container'>
                  <ReactPaginate
                    activeClassName={'item active '}
                    breakClassName={'item break-me '}
                    breakLabel={'...'}
                    containerClassName={'pagination'}
                    disabledClassName={'disabled-page'}
                    marginPagesDisplayed={1}
                    nextClassName={"item next "}
                    nextLabel={<ArrowForwardIosIcon style={{ fontSize: 18, width: 40 }} />}
                    pageClassName={'item pagination-page '}
                    previousClassName={"item previous"}
                    previousLabel={<ArrowBackIosIcon style={{ fontSize: 18, width: 40 }} />}
                    onPageChange={handlePageClickB}
                    pageRangeDisplayed={1}
                    pageCount={pageCountB}
                    // forcePage={pageNumber}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            )

          }
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
      <div className='drawer_tabs'>
        {
          schemeLoading? (             
          <>
            {[...Array(10)].map((x, i) =>              
              <Stack key={i} direction="row" spacing={1} sx={{width: "100%", marginTop: '12PX'}}>
                <Stack direction={"column"} alignItems="center" sx={{width: "100%", marginTop: '12PX'}} spacing={0.5}>
                  <Skeleton variant="text" width={"60%"} height={20} animation="wave"></Skeleton>
                  <Skeleton variant="text" width={"80%"} height={20} animation="wave"></Skeleton>
                </Stack>
                <Stack sx={{width: "30%"}}>
                  <Skeleton variant="rectangular" height={'60px'} animation="wave"></Skeleton>
                </Stack>
              </Stack>
            )}
          </>
            ) : (
              <>
                {
                  currentItemsS && currentItemsS.map((item,index)=>{
                    return (
                      <SchemeData key={index} schemeData={item} />
                    )
                  })
                }
                <div className='navigation_container'>
                  <ReactPaginate
                    activeClassName={'item active '}
                    breakClassName={'item break-me '}
                    breakLabel={'...'}
                    containerClassName={'pagination'}
                    disabledClassName={'disabled-page'}
                    marginPagesDisplayed={1}
                    nextClassName={"item next "}
                    nextLabel={<ArrowForwardIosIcon style={{ fontSize: 18, width: 40 }} />}
                    pageClassName={'item pagination-page '}
                    previousClassName={"item previous"}
                    previousLabel={<ArrowBackIosIcon style={{ fontSize: 18, width: 40 }} />}
                    onPageChange={handlePageClickS}
                    pageRangeDisplayed={1}
                    pageCount={pageCountS}
                    // forcePage={pageNumber}
                    renderOnZeroPageCount={null}
                  />                  
                </div>
              </>
              )
        }          
      </div>
      </TabPanel> */}
      {/* <TabPanel value={value} index={3}>
        <div className='drawer_tabs'>
        {
          manifestoData === null? (             
          <>
            {[...Array(10)].map((x, i) =>              
              <Stack key={i} direction="row" spacing={1} sx={{width: "100%", marginTop: '12PX'}}>
                <Stack sx={{width: "100%"}}>
                  <Skeleton style={{margin: 'auto'}} variant="text" width={"60%"} height={40} animation="wave"></Skeleton>
                  <Skeleton variant="rectangular" height={'120px'} animation="wave"></Skeleton>
                </Stack>
              </Stack>
            )}
          </>
            ) : (
              <>
              {
                currentItemsM && currentItemsM.map((item,index)=>{
                  return (
                    <Manifesto key={item.manifesto_id} manifestoData={item}></Manifesto>
                  )
                })
              }
              <div className='navigation_container'>
                <ReactPaginate
                  activeClassName={'item active '}
                  breakClassName={'item break-me '}
                  breakLabel={'...'}
                  containerClassName={'pagination'}
                  disabledClassName={'disabled-page'}
                  marginPagesDisplayed={1}
                  nextClassName={"item next "}
                  nextLabel={<ArrowForwardIosIcon style={{ fontSize: 18, width: 40 }} />}
                  pageClassName={'item pagination-page '}
                  previousClassName={"item previous"}
                  previousLabel={<ArrowBackIosIcon style={{ fontSize: 18, width: 40 }} />}
                  onPageChange={handlePageClickM}
                  pageRangeDisplayed={1}
                  pageCount={pageCountM}
                  // forcePage={pageNumber}
                  renderOnZeroPageCount={null}
                />                  
              </div>
            </>
          )
        }

        </div>
      </TabPanel> */}
    </Box>
  );
}