import React, { useEffect, useState } from 'react';

import './Video.css';
import twitter from './twitter-x-logo.png';
import getVideoId from 'get-video-id';
import { WhatsappIcon, FacebookIcon, TwitterIcon, XIcon, TwitterShareButton, FacebookShareButton, WhatsappShareButton } from "react-share";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const VideoBeneficiary = (props) => {

  let shareData = props.shareData;
  var imgSource;
  const youtubeVideoID = getVideoId(props.beneficiaryData.source_link);
  if (props.beneficiaryData.source.toLowerCase() == "twitter") {
    imgSource = twitter;
  } else if (props.beneficiaryData.source.toLowerCase() == "youtube") {
    imgSource = `http://img.youtube.com/vi/${youtubeVideoID.id}/default.jpg`;
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [text, setText] = useState('');
  const open = Boolean(anchorEl);

  useEffect(() => {
    // const script = document.createElement("script");
    // script.async = true;
    // script.defer = true;
    // script.src = "//matomo.latlong.in/";
    // document.head.appendChild(script);
    // script.onload = () => {
    //   window._paq = window._paq || [];
    //   window._paq.push(['trackPageView']);
    //   window._paq.push(['enableLinkTracking']);
    //   window._paq.push(['setSiteId', '39']);
    // };
  });

  const handleMenuItemClick = (itemName, itemIndex) => {
    // window._paq.push(['trackEvent', 'Beneficiary share click', itemName, itemIndex]);
  }

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const copy = () => {
    // staging
    setText(encodeURI(`https://shwetapatra.com/?shared=true&state_id=${shareData.state_id}&area_id=${shareData.area_id}&tab=1&acc=${props.beneficiaryData.beneficiary_id}`));
    // local
    // setText(encodeURI(`http://localhost:3000/?shared=true&state_id=${shareData.state_id}&area_id=${shareData.area_id}&tab=1&acc=${props.beneficiaryData.beneficiary_id}`))
  }

  useEffect(() => {
    navigator.clipboard.writeText(text);
  }, [text])

  function formatDate(dateString) {
    const originalDate = new Date(dateString);
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <div className='video'>
      <div className='video-profile'>
        <img src={imgSource} alt="" style={{borderRadius: '12px'}} />
      </div>
      <div className='video-info'>
        <div className='video-info__link'>
          <p>{props.beneficiaryData.scheme_name}</p>
        </div>
        <div className='video-info__scheme'>
          <small style={{ fontSize: '10px' }}>{formatDate(props.beneficiaryData.video_published_date)}</small>
        </div>
      </div>
      <Button
        id="share-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ShareIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={(e) => { e.stopPropagation(); handleMenuItemClick('Copy Link', 0) }}>
          <ContentCopyRoundedIcon onClick={copy} fontSize='medium' ></ContentCopyRoundedIcon>
        </MenuItem>
        <MenuItem onClick={(e) => {  e.stopPropagation(); handleMenuItemClick('Twitter', 1)}}>
          <TwitterShareButton
            url={encodeURI(`https://shwetapatra.com/?shared=true&state_id=${shareData.state_id}&area_id=${shareData.area_id}&tab=1&acc=${props.beneficiaryData.beneficiary_id}`)}
            hashtags={["samruddhi", "karnataka"]}
          >
            <XIcon size={32} round={true} />
          </TwitterShareButton>
        </MenuItem>
        <MenuItem onClick={(e) => { e.stopPropagation(); handleMenuItemClick('Facebook', 2) }}>
          <FacebookShareButton
            url={encodeURI(`https://shwetapatra.com/?shared=true&state_id=${shareData.state_id}&area_id=${shareData.area_id}&tab=1&acc=${props.beneficiaryData.beneficiary_id}`)}
          >
            <FacebookIcon size={32} round={true}></FacebookIcon>
          </FacebookShareButton>
        </MenuItem>
        <MenuItem onClick={(e) => { e.stopPropagation(); handleMenuItemClick('Whatsapp', 3) }}>
          <WhatsappShareButton
            url={encodeURI(`https://shwetapatra.com/?shared=true&state_id=${shareData.state_id}&area_id=${shareData.area_id}&tab=1&acc=${props.beneficiaryData.beneficiary_id}`)}
          >
            <WhatsappIcon size={32} round={true}></WhatsappIcon>
          </WhatsappShareButton>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default VideoBeneficiary;