import React from 'react';
import './SchemeData.css';

const schemeData = (props, index) => {
  let voter_percentage = ((props.schemeData.adult_population / props.schemeData.area_population) * 100).toFixed(0);
  let householdPercentage = props.schemeData.percentage_households;
  let area_name = props.schemeData.area_name.split(';');
  return (
    <div className='info-container'>
      <div className='beneficiary_area-name'>
      <h5>{area_name[0]}</h5>
      <small>{props.schemeData.scheme_name}</small>
      </div>

      { 
        props.schemeData.scheme_name.toLowerCase() == 'jan aushadhi kendra' ?           
          <div className='info_percentages'>
            <small> Kendras </small>
            <h2>{props.schemeData.beneficiaries.toLocaleString("en-IN")}</h2>
          </div>             
          : 
           <>
            {
              props.schemeData.fund_allocated != '' ? 
              <div className='info_percentages' style={{width: '35%'}}>
                <small> Funds Allocated</small>
                <h4>{props.schemeData.fund_allocated.toLocaleString("en-IN")}</h4>
              </div> 
              : 
              <div className='info_percentages'>
                <small> Beneficiaries</small>
                <h4>{props.schemeData.beneficiaries.toLocaleString("en-IN")}</h4>
              </div> 
            } 
              <>
                {/* { householdPercentage != 0 ? 
                  <div className='info_percentages'> 
                    <small>Coverage</small> 
                    <h2> {householdPercentage}% </h2> 
                  </div>
                  :
                  <></>
                }  */}
              </>
            </>               
        
      }

    </div>
  )
};

export default schemeData;