import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, Paper } from '@mui/material';
import Header from './subcomponents/DashboardHeader';
import axios from 'axios';
import Loader from './subcomponents/DashboardLoader';
import { CheckCircleOutline } from '@mui/icons-material';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const defaultTheme = createTheme();

export default function SignIn() {
    const [otpSent, setOtpSent] = React.useState(false);
    const [contactNumber, setContactNumber] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [otp, setOtp] = React.useState('')
    const signIn = useSignIn()
    const location = useLocation()
    const navigate = useNavigate()

    const session = useIsAuthenticated()
    // console.log(session, "session")

    if (session) {
        // console.log('redirecting')
        return (<Navigate to="/dashboard" replace state={{ from: location }} />)
    }

    const sendOtp = async () => {
        setLoading(true)
        try {
            let body = {
                type: 'mobile',
                receiver: contactNumber
            };
            let response = await axios.post(
                `${process.env.REACT_APP_API_URL}/dashboard/send_otp`,
                body,
            );
            // console.log(response.data)
            if (response.data.confirmation_code_length) {
                setErrorMessage('')
                setLoading(false)
                setOtpSent(true)
            } else {
                setErrorMessage('Something went wrong')
                setLoading(false)
                setOtpSent(false)
            }
            // router.push({ pathname: '/(auth)/verify', params: { confirmationCodeLength: response.data.confirmation_code_length, successMessage: 'OTP sent successfully', receiver: phoneNumber } })
        } catch (err) {
            let error = err.response?.data?.message ? err.response.data.message : 'Something went wrong'
            setErrorMessage(error)
            setLoading(false)
        }
    }

    const handleVerify = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            // showLoader()
            let body = {
                type: 'mobile',
                receiver: contactNumber,
                verification_code: otp,
            }
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/verify_otp`, body)
            let authToken = response.data.authToken;
            let user = response.data.user
            // console.log(response)
            setErrorMessage('')
            setTimeout(() => {
                setLoading(false)
                signIn({
                    auth: {
                        token: authToken,
                        type: 'Bearer',
                    },
                    userState: {
                        name: user.name,
                        uid: user.id,
                        role: user.role,
                    }
                })
                navigate('/dashboard', { replace: true })
            }, 1000)
        } catch (err) {
            // hideLoader()
            setLoading(false)
            let error = err.response?.data?.message ? err.response.data.message : 'Something went wrong'
            setErrorMessage(error)
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Header />
            <Paper sx={{ margin: '20px', background: '#DFF5FF', padding: '5px', minHeight: 'calc( 100vh - 104px )', display: 'flex', flexDirection: 'column' }} elevation={2}>
                <Container component="main" maxWidth="xs" sx={{ p: 5 }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#4381c3' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography variant="h6" component="div" style={{ fontWeight: 'bold', color: '#4381c3', fontFamily: "'Itim', sans-serif", cursor: 'pointer' }}>
                            White Paper
                        </Typography>
                        <Typography variant="h6" component="div" style={{ fontWeight: 'bold', color: '#387ADF', fontFamily: "'Itim', sans-serif", cursor: 'pointer', marginTop: '50px' }}>
                            Sign In
                        </Typography>
                        <Box component="form" onSubmit={handleVerify} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phone"
                                label="Contact Number"
                                name="phone"
                                autoComplete="phone"
                                autoFocus
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                            />
                            {otpSent &&
                                <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
                                    OTP Sent.
                                </Alert>
                            }
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3, mb: 2, borderRadius: '10px', background: '#1aa950', ":hover": {
                                        background: '#1aa950'
                                    }
                                }}
                                disabled={contactNumber.length != 10}
                                onClick={() => sendOtp(contactNumber)}
                            >
                                {otpSent ? 'RESEND OTP' : 'SEND OTP'}
                            </Button>
                            {
                                otpSent &&
                                <>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="otp"
                                        label="Enter OTP"
                                        type="password"
                                        id="password"
                                        value={otp}
                                        autoComplete="current-password"
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, borderRadius: '10px', background: '#4381c3' }}
                                        onClick={handleVerify}
                                        disabled={!otp}
                                    >
                                        Sign In
                                    </Button>
                                </>
                            }
                            {errorMessage &&
                                <Alert severity="error">{errorMessage}.</Alert>
                            }
                        </Box>
                    </Box>
                </Container>
                <Loader open={loading} />
            </Paper>
        </ThemeProvider>
    );
}
