import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config/config';

const SchemeApi = ({ onSchemeFetched, selectedConstituency }) => {


  useEffect(() => {
    const fetchSchemeData = async () => {
      try {
        const response = await axios.get(`${API_URL}/samruddhi_v2/get_schemes?language=english&area_id=${selectedConstituency.id}`);
        const data = response.data.schemes; 
        onSchemeFetched(data);
      } catch (error) {
        // console.error('Error fetching scheme data:', error);
      }
    };

    fetchSchemeData();
  }, [selectedConstituency]);

  return null;
};

export default SchemeApi;
