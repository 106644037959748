import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import SamruddhiImage from './landing_background.png';
import BjpLogo from './BJP-logo.png';
import AutocompleteShared from './AutocompleteShared';
import mapboxgl from 'mapbox-gl';
import './Map.css';
import Loader from './Loader';
import LatlongLogo from './Latlong-Logo.png';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

var cleanUp;

const MapShared = (props) => {
  const mapContainer = useRef(null);
  const [clickedFeature, setClickedFeature] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [count, setCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [areaOrder, setAreaOrder] = useState(null);
  const [stylesLoaded, setStylesLoaded] = useState(false)
  const [sourceLoaded, setSourceLoaded] = useState(false);
  let map = useRef();
  let setVideos = props.setVideos;
  let shareData = props.shareData;
  let setBeneficiaryData = props.setBeneficiaryData;
  let setShareData = props.setShareData;
  let setSchemeData = props.setSchemeData;
  let setManifestoData = props.setManifestoData;
  let setInititalUI = props.setInititalUI;
  let initialUI = props.initialUI
  let setOpen = props.setOpen
  let setVideosLoading = props.setVideosLoading;
  let setBeneficiaryLoading = props.setBeneficiaryLoading;
  let setSchemeLoading = props.setSchemeLoading;

  useLayoutEffect(() => {

    if (map.current) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: 'map',
      zoom: 3.1,
      minZoom: 3,
      maxZoom: 18,
      center: [82.71, 23.31],
      style: 'mapbox://styles/mapbox/streets-v11'
      // style:'https://tiles.latlong.in/map/styles/openmapstyle.json'
    });
    map.current.resize();

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    })


    map.current.on('load', () => {
      //state_level
      map.current.addSource('state_level', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': stateData
        }
      });

      map.current.addLayer({
        'id': 'state_level_layer',
        'type': 'fill',
        'source': 'state_level', // reference the data source
        'layout': {},
        'maxzoom': 8,
        'minzoom': 5,
        'paint': {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      });

      map.current.addLayer({
        'id': 'state_level_outline',
        'type': 'line',
        'source': 'state_level',
        'layout': {},
        'maxzoom': 8,
        'minzoom': 5,
        'paint': {
          'line-color': '#000',
          'line-width': 3
        }
      });

      //child level
      map.current.addSource('child_level', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': childData
        }
      });

      map.current.addLayer({
        'id': 'child_level_layer',
        'type': 'fill',
        'source': 'child_level', // reference the data source
        'layout': {},
        'maxzoom': 18,
        'minzoom': 8,
        'paint': {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      });

      map.current.addLayer({
        'id': 'child_level_outline',
        'type': 'line',
        'source': 'child_level',
        'layout': {},
        'maxzoom': 18,
        'minzoom': 8,
        'paint': {
          'line-color': '#000',
          'line-width': 3
        }
      });

      // parent level
      map.current.addSource('parent_level', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': parentData
        }
      });

      map.current.addLayer({
        'id': 'parent_level_layer',
        'type': 'fill',
        'source': 'parent_level', // reference the data source
        'layout': {},
        'maxzoom': 5,
        'minzoom': 3,
        'paint': {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      });

      map.current.addLayer({
        'id': 'parent_level_outline',
        'type': 'line',
        'source': 'parent_level',
        'layout': {},
        'maxzoom': 5,
        'minzoom': 3,
        'paint': {
          'line-color': '#000',
          'line-width': 3
        }
      });
    });
  }, [])

  useEffect(() => {
    if (count == 0 && (stateData.length >= 1)) {
      map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
      setCount(1);
    }
    if (stateData.length >= 1) {
      const state_map_layer = map.current.getLayer('state_level');
      // console.log(state_map_layer);
      const state_map_source = map.current.getSource('state_level');
      if (typeof state_map_source === 'undefined') {
        //state_level
        map.current.addSource('state_level', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': stateData
          }
        });

        map.current.addLayer({
          'id': 'state_level_layer',
          'type': 'fill',
          'source': 'state_level', // reference the data source
          'layout': {},
          'maxzoom': 8,
          'minzoom': 5,
          'paint': {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });

        map.current.addLayer({
          'id': 'state_level_outline',
          'type': 'line',
          'source': 'state_level',
          'layout': {},
          'maxzoom': 8,
          'minzoom': 5,
          'paint': {
            'line-color': '#000',
            'line-width': 3
          }
        });

        setStylesLoaded(true)
        setSourceLoaded(true)

      } else {
        //setTimeout(() => { 
        map.current.getSource('state_level').setData({
          'type': 'FeatureCollection',
          'features': stateData
        }
        )//, 2000});
      }
    }
  }, [stateData, initialUI]);

  useEffect(() => {
    if (childData.length >= 1) {
      const child_map_source = map.current.getSource('child_level');
      if (typeof child_map_source === 'undefined') {
        //child level
        map.current.addSource('child_level', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': childData
          }
        });

        map.current.addLayer({
          'id': 'child_level_layer',
          'type': 'fill',
          'source': 'child_level', // reference the data source
          'layout': {},
          'maxzoom': 18,
          'minzoom': 8,
          'paint': {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });

        map.current.addLayer({
          'id': 'child_level_outline',
          'type': 'line',
          'source': 'child_level',
          'layout': {},
          'maxzoom': 18,
          'minzoom': 8,
          'paint': {
            'line-color': '#000',
            'line-width': 3
          }
        });
      } else {
        //setTimeout(() => { 
        map.current.getSource('child_level').setData({
          'type': 'FeatureCollection',
          'features': childData
        }
        )//, 2000});
      }
    }
  }, [childData]);

  useEffect(() => {
    if (parentData.length >= 1) {
      const parent_map_data = map.current.getSource('parent_level');
      if (typeof parent_map_data === 'undefined') {
        // parent level
        map.current.addSource('parent_level', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': parentData
          }
        });

        map.current.addLayer({
          'id': 'parent_level_layer',
          'type': 'fill',
          'source': 'parent_level', // reference the data source
          'layout': {},
          'maxzoom': 5,
          'minzoom': 3,
          'paint': {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });

        map.current.addLayer({
          'id': 'parent_level_outline',
          'type': 'line',
          'source': 'parent_level',
          'layout': {},
          'maxzoom': 5,
          'minzoom': 3,
          'paint': {
            'line-color': '#000',
            'line-width': 3
          }
        });
      } else {
        //setTimeout(() => { 
        map.current.getSource('parent_level').setData({
          'type': 'FeatureCollection',
          'features': parentData
        }
        )//, 2000});
      }
    }
  }, [parentData]);

  useEffect(() => {
    try {
      map.current.on('styles.load', (e) => {
        setStylesLoaded(true)
      })
      map.current.on('sourcedata', (e) => {
        setSourceLoaded(true)
      })
      const parent_source = map.current.getSource("parent_level");
      parent_source.setData({
        'type': 'FeatureCollection',
        'features': parentData
      });
      const child_source = map.current.getSource("child_level");
      child_source.setData({
        'type': 'FeatureCollection',
        'features': childData
      })
      const state_source = map.current.getSource("state_level");
      state_source.setData({
        'type': 'FeatureCollection',
        'features': stateData
      });
    } catch (error) {
      console.log(error.message)

    }
  }, [stateData, childData, parentData])


  return (

    <div className={`map-container-parent ${initialUI ? 'initialUI' : ''}`}>

      {/* {initialUI && <div><div className='blur-background'><img src={SamruddhiImage}></img></div><div className='latlong-watermark'><img src={LatlongLogo}></img><h5>AALOKA</h5></div><div className='bjp_logo'><img src={BjpLogo}></img></div></div>}  */}

      <div className="map-container" id='map' ref={mapContainer}>
        {showLoader && <Loader />}
        {/* {!initialUI && <div><div className='latlong-watermark'><img src={LatlongLogo}></img></div><div className='bjp_logo'><img src={BjpLogo}></img></div></div>} */}
      </div>
      {
        // stylesLoaded && sourceLoaded &&
        <AutocompleteShared setOpen={setOpen} map={map} setAreaOrder={setAreaOrder} clickedFeature={clickedFeature} setStateData={setStateData} setChildData={setChildData} setParentData={setParentData} setShowLoader={setShowLoader} stateData={stateData} setVideos={setVideos} setBeneficiaryData={setBeneficiaryData} setInititalUI={setInititalUI} setSchemeData={setSchemeData} setManifestoData={setManifestoData} setShareData={setShareData} shareData={shareData} setVideosLoading={setVideosLoading} setBeneficiaryLoading={setBeneficiaryLoading} setSchemeLoading={setSchemeLoading} />
      }
    </div>
  );
};

export default MapShared;
