import React from 'react'
import spinner from './../assets/latlongpin.png'
import styled,{ keyframes } from "styled-components";

const OverBlur=styled.div`
    background: rgba(233,233,233,0.5);    
    position: fixed;  
    z-index: 5000;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    height: 100%;
    width:100%;
`
const spin = keyframes`
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
`   ;

const Loaderdiv=styled.div`
    width: 80px;
    height: 80px;
    zIndex: 6000;
    position: fixed;
    top: 40%;
    left: 47%;
    opacity: 1 !important;
    animation: ${spin} 2s linear infinite;
    `


const Loader =(props) =>{
   return(
    <OverBlur>
        <Loaderdiv> 
            <img src={spinner} style={{position: 'absolute',width:'100%', marginRight: '10%'}} alt="latlong spinner"/>
        </Loaderdiv>
    </OverBlur>
   )
}

export default Loader