import React, { useEffect } from "react";
import './YoutubeEmbed.css';
import getVideoId from 'get-video-id';
import ReactPlayer from "react-player";

const YoutubeEmbed = (props) => {
  const youtubeVideoID = getVideoId(props.sourceLink);
  const activeVideo = props.activeVideo;
  const setActiveVideo = props.setActiveVideo;

  const stopAllVideos = () => {
    const parentDiv = document.getElementById(props.video_id);
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(iframe => {
      // Check if the iframe is not a descendant of the parent div
      if (parentDiv && !parentDiv.contains(iframe)) {
        if (
          iframe.src.includes('youtube') ||
          iframe.src.includes('vimeo') ||
          iframe.src.includes('dailymotion')
        ) {
          iframe.contentWindow.postMessage(
            JSON.stringify({ event: 'command', func: 'pauseVideo' }),
            '*'
          );
        }
      }
    });
  };

  useEffect(() => {
    stopAllVideos();
  }, [activeVideo]);

  return (
    <div className="video-responsive_parent" id={props.video_id}>
      <div className="video-responsive">
        <ReactPlayer
          url={props.sourceLink}
          controls
          onPlay={() => {
            stopAllVideos()
            // setActiveVideo(props.sourceLink);
          }}
          onStart={() => {
            setActiveVideo(props.sourceLink);
          }}
          onProgress={() => {
            if (activeVideo !== props.sourceLink) {
              stopAllVideos();
            }
          }}
        />
      </div>
    </div>
  );
};

export default YoutubeEmbed;
