import React from "react";
// import {RequireAuth} from "react-auth-kit/AuthProvider";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
const PrivateRoutes = () => {

    const location = useLocation();
    // const { authLogin } = /* some auth state provider */

    let authLogin = true
    if (authLogin === undefined) {
        return null; // or loading indicator/spinner/etc
    }

    return authLogin
        ? <AuthOutlet fallbackPath="/dashboard/login"><Outlet /></AuthOutlet>
        : <Navigate to="/dashboard/login" replace state={{ from: location }} />;
}

export default PrivateRoutes;
