import React, { useState, useEffect } from 'react';

// import TopBar from './components/TopBar';
import Map from './Map';
// import PersonaltiesTabs from './components/PersonalitiesTabs';
// import mapboxgl from 'mapbox-gl';
import DrawerData from './DrawerData';
// import "../node_modules/video-react/dist/video-react.css";
import { useParams } from 'react-router-dom';

import Header from './../Header';

const Samruddhi = (props) => {

  const params = useParams();
  const area_id_from_prop = props.area;
  const state_id_from_prop = props.state;
  const [areaId, setAreaId] = useState(props.area);
  const [stateId, setStateId] = useState(props.state);
  const [initialUI, setInititalUI] = useState(true)
  const [videos, setVideos] = useState(null);
  const [beneficiaryData, setBeneficiaryData] = useState(null);
  const [schemeData, setSchemeData] = useState(null);
  const [manifestoData, setManifestoData] = useState(null);
  const [shareData, setShareData] = React.useState([null, null, null])
  const [open, setOpen] = React.useState(false);
  const [videosLoading, setVideosLoading] = React.useState(false);
  const [beneficiaryLoading, setBeneficiaryLoading] = React.useState(false);
  const [schemeLoading, setSchemeLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  let sharedOpen = 0
  let anchorClicked = 0
  let tab = props.tab
  let acc = props.acc
  let shared = props.shared
  let pdfShow = props.pdfShow;
  let setIsModalOpen = props.setIsModalOpen;

  // console.log(areaId, 'areaID', stateId, 'stateID',)

  useEffect(() => {
    setAreaId(area_id_from_prop)
  }, [area_id_from_prop])

  useEffect(() => {
    setStateId(state_id_from_prop)
  }, [state_id_from_prop])

  useEffect(() => {
    if (pdfShow) {
      setOpen(false);
    }
  }, [pdfShow])

  useEffect(() => {
    // console.log(shared, tab, acc, "Sharing")

    if (beneficiaryData && videos && shared) {

      if (tab == 0) {
        for (let i = 0; i < videos.length; i++) {
          if (videos[i].video_id == acc) {
            var position = i;
            const removedItem = videos.splice(i, 1)[0];
            videos.unshift(removedItem);
            break;
          }
        }
      } else {
        for (let i = 0; i < beneficiaryData.length; i++) {
          if (beneficiaryData[i].beneficiary_id == acc) {
            var position = i;
            const removedItem = beneficiaryData.splice(i, 1)[0];
            beneficiaryData.unshift(removedItem);

            break;
          }
        }
      }

      const timer = setTimeout(() => {
        if (sharedOpen == 0) {
          setOpen(true)
          sharedOpen = 1
        }
        document.getElementById(`simple-tab-${tab}`).click();
        const anchor = `l_${acc}`
        if (anchor && beneficiaryData && videos) {
          if (tab == 0) {
            document.getElementById(`simple-tab-${1}`).click();
          } else {
            document.getElementById(`simple-tab-${0}`).click();
          }
          document.getElementById(`simple-tab-${tab}`).click();

          const anchorEl = document.getElementById(anchor);
          // console.log(anchorEl, 'anchorEl')
          if (anchorEl) {
            // console.log(anchorEl, 'anchorEl')

            anchorEl.style.backgroundColor = "rgba(137,207,240,0.9)";
          }
          const anchorElSum = document.getElementById(`l_s_${acc}`);
          if (anchorEl != null && (anchorClicked == 0)) {
            anchorEl.scrollIntoView();
            anchorElSum.click();
            anchorClicked = 1
          }
        }
        return () => clearTimeout(timer);
      }, 1000);
    }

  }, [beneficiaryData, videos, shared, tab, acc]);


  return (
    <div style={{ height: '100%', width: '100%', marginTop: '-12px', overflow: 'hidden' }}>

      <Map videos={videos} setVideos={setVideos} setInititalUI={setInititalUI} initialUI={initialUI} setBeneficiaryData={setBeneficiaryData} setSchemeData={setSchemeData} setManifestoData={setManifestoData} setShareData={setShareData} setOpen={setOpen} setVideosLoading={setVideosLoading} setBeneficiaryLoading={setBeneficiaryLoading} setSchemeLoading={setSchemeLoading} areaId={areaId} setAreaId={setAreaId} stateId={stateId} setStateId={setStateId} setIsModalOpen={setIsModalOpen} />

      {!initialUI && <DrawerData videos={videos} beneficiaryData={beneficiaryData} schemeData={schemeData} manifestoData={manifestoData} setOpen={setOpen} open={open} shareData={shareData} videosLoading={videosLoading} beneficiaryLoading={beneficiaryLoading} schemeLoading={schemeLoading} pageNumber={pageNumber} />}
    </div>
  );
}

export default Samruddhi;
