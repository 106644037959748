import { Icon, MinimalButton, Position, Tooltip } from '@react-pdf-viewer/core';
import { NextIcon, PreviousIcon } from '@react-pdf-viewer/search';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { searchPlugin } from "@react-pdf-viewer/search";
import { RenderSearchProps, Search } from '@react-pdf-viewer/search';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { ZoomInIcon, ZoomOutIcon } from '@react-pdf-viewer/zoom';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import { DownloadIcon } from '@react-pdf-viewer/open';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import { RenderShowSearchPopoverProps } from '@react-pdf-viewer/search';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


const Modal = ({ setChartPdfShow, chartPdfShow, setIsModalOpen, samruddhiV1 }) => {

  // const searchPluginInstance = searchPlugin();

  const searchPluginInstance = searchPlugin();
  const { Search } = searchPluginInstance;
  const [readyToSearch, setReadyToSearch] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const downloadFileUrl = '/pdfs/white_paper_charts.pdf';

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [currentPageRefresh, setCurrentPageRefresh] = useState(0);
  // const [scale, setScale] = useState(1); 
  const { ShowSearchPopoverButton, ShowSearchPopover } = searchPluginInstance;
  const handlePageChange = (e) => {
    localStorage.setItem("current-page", `${e.currentPage}`);
  };

  useEffect(() => {
    // Find the button element by class name
    const closeButton = document.querySelector('.rpv-core__button');

    // Check if the button element exists
    if (closeButton) {
      // Remove inner HTML content
      closeButton.innerHTML = '';

      // Create an instance of CloseIcon component
      // const closeIcon = <CloseIcon />;

      // Append the CloseIcon component to the button element
      // closeButton.appendChild(closeIcon);
    }
  }, []);

  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
  } = pageNavigationPluginInstance;
  const zoomPluginInstance = zoomPlugin();
  const getFilePluginInstance = getFilePlugin();
  const { DownloadButton } = getFilePluginInstance;
  const currentPage = localStorage.getItem("current-page");
  const initialPage = currentPage ? parseInt(currentPage, 10) : 0;

  const togglePdfShow = () => {
    // setIsModalOpen(true);
    setChartPdfShow(prevState => !prevState);
  };


  useEffect(() => {
    if (chartPdfShow) {
      scrollToFirstPage();
    }
  }, [chartPdfShow])

  const scrollToFirstPage = () => {
    setCurrentPageRefresh(0);
  };

  const togglePopoverVisibility = () => {
    const searchModal = document.querySelector('.rpv-core__popover-body-search');
    setIsPopoverVisible(prevState => !prevState);
    if (searchModal) {
      setIsPopoverVisible(false);
    }
    const button = document.querySelector('.rpv-core__button');
    if (button) {
      button.addEventListener('click', function (event) {
        // console.log(event, 'event')
        setIsPopoverVisible(false);
      });
    }
  };

  // const increaseZoom = () => {
  //   setScale(scale + 0.1);
  // };

  // const decreaseZoom = () => {
  //   if (scale > 0.1) {
  //     setScale(scale - 0.1);
  //   }
  // };

  return (
    <>
      <div className="modal" style={{ zIndex: '10', marginTop: '36px' }}>
        <div style={{ position: 'fixed', top: '60px', right: '6px', zIndex: '10001' }}>
          <Fab size="small" color="secondary" aria-label="add" onClick={() => togglePdfShow()} style={{ cursor: 'pointer' }}>
            {!chartPdfShow ?
              <PictureAsPdfIcon></PictureAsPdfIcon>
              :
              <CloseIcon></CloseIcon>
            }
          </Fab>
        </div>
        <div className="modal-content">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div className="rpv-core__viewer viewer-wrapper">
              <div className="top-bar" style={{ display: 'flex', width: samruddhiV1 ? '100%' : '100%' }} >
                <div style={{ padding: "0px 2px" }}    >
                  {/* <ShowSearchPopover /> */}

                  <ShowSearchPopover>
                    {(props) => (
                      <button
                        style={{
                          backgroundColor: '#357edd',
                          border: 'none',
                          borderRadius: '4px',
                          color: '#ffffff',
                          cursor: 'pointer',
                          padding: '8px',
                        }}
                        onClick={() => {
                          props.onClick();
                        }}
                      >
                        <span style={{ margin: '0' }} onTouchStart={togglePopoverVisibility} >Search</span>
                      </button>
                    )}
                  </ShowSearchPopover>

                  {/* <PdfSearch /> */}
                  {/* <ShowSearchPopover></ShowSearchPopover> */}
                </div>
                {/* <div style={{ padding: "0px 2px" }}>
                  <ZoomInIcon />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomOutIcon />
                </div> */}
                <div style={{ padding: "0px 2px" }}>
                  <GoToFirstPageButton />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <GoToPreviousPage />
                </div>
                <div style={{ padding: "0px 2px", width: '3rem' }}>
                  <CurrentPageInput />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <GoToNextPageButton />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <GoToLastPageButton />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  {/* <DownloadButton /> */}
                  <a href={downloadFileUrl} download="white_paper_2024.pdf">
                    <FileDownloadOutlinedIcon style={{color: 'rgba(0,0,0,0.3)'}}></FileDownloadOutlinedIcon>
                  </a>
                </div>
              </div>
              <div style={{ padding: "0px 2px" }}>
                {/* <Search>
                  {(renderSearchProps) => {
                    const { jumpToNextMatch, jumpToPreviousMatch } = renderSearchProps
                    return (
                      <>
                        <div
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            display: 'flex',
                            padding: '0 2px',
                          }}
                        >
                          <input
                            style={{
                              border: 'none',
                              padding: '8px',
                              width: '200px',
                            }}
                            placeholder="Enter to search"
                            type="text"
                            value={renderSearchProps.keyword}
                            onChange={(e) => {
                              setReadyToSearch(false);
                              renderSearchProps.setKeyword(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13 && renderSearchProps.keyword) {
                                setReadyToSearch(true);
                                renderSearchProps.search();
                              }
                            }}
                          />
                           <div style={{ padding: '0 2px' }}>
                                    <Tooltip
                                        position={Position.BottomCenter}
                                        target={
                                            <MinimalButton onClick={renderSearchProps.jumpToPreviousMatch}>
                                                <PreviousIcon />
                                            </MinimalButton>
                                        }
                                        content={() => 'Previous match'}
                                        offset={{ left: 0, top: 8 }}
                                    />
                                </div>
                                <div style={{ padding: '0 2px' }}>
                                    <Tooltip
                                        position={Position.BottomCenter}
                                        target={
                                            <MinimalButton onClick={renderSearchProps.jumpToNextMatch}>
                                                <NextIcon />
                                            </MinimalButton>
                                        }
                                        content={() => 'Next match'}
                                        offset={{ left: 0, top: 8 }}
                                    />
                                </div>
                        </div>

                      </>
                    );
                  }}
                </Search> */}


              </div>
              <div style={{ height: "720px", minWidth: '300px', position: 'relative', top: isPopoverVisible ? '70px' : '0px' }}>
                <Viewer
                  fileUrl={'/pdfs/white_paper_charts.pdf'}
                  initialPage={initialPage}
                  page={currentPageRefresh}
                  onPageChange={handlePageChange}
                  plugins={[searchPluginInstance, pageNavigationPluginInstance, zoomPluginInstance, getFilePluginInstance]}
                  defaultScale={0.5}
                />
                {/* <div style={{position: 'relative', }}>
                  <button onClick={increaseZoom}>Zoom In</button>
                  <button onClick={decreaseZoom}>Zoom Out</button>
                </div> */}
              </div>
            </div>
          </Worker>
        </div>
      </div >
    </>
  );
};

export default Modal;
