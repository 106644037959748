import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const BarGraph = (props) => {

  let data = props.data;
  // const [inverted, setInverted] = useState(false);

  // Update 'inverted' based on 'data.Unit'
  // For example:
  // useEffect(() => {
  //   setInverted(data.Heading === "Logistics Performance Index" || data.Heading === "Global Innovation Index" || data.Heading === "Harvard Economic Complexity Index");
  //   console.log('hiii')
  // }, [data.Heading]);

  const yAxisTitle = data.Unit;
  const rotateLabel = yAxisTitle.length > 1 ? -90 : 0;

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 200,
      width: 240,
      toolbar: {
        show: false // Hide the download option
      }
    },

    xaxis: {
      categories: [`${data.remarks_then}`, `${data.remarks_now}`],
      labels: {
        rotate: -45, // Rotate labels to fit them properly
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          colors: ['#000000'],
        },
        // formatter: function (value, timestamp, index) {
        //   return value.split('\n');
        // },
        // formatter: function (value, timestamp, index) {
        //   if (value.length > 14) {
        //     // Wrap the label to the next line if its length exceeds 14 characters
        //     return value.match(/.{1,14}/g); // Split the label into chunks of 14 characters
        //   }
        //   return value; // Return the original label if its length is within the threshold
        // },
        // formatter: function (value, timestamp, index) {
        //   if (value.length > 14) {
        //     // Split the label into chunks based on spaces
        //     const words = value.split(' ');
        //     let lines = [];
        //     let line = '';
        //     for (const word of words) {
        //       if ((line + word).length > 14) {
        //         lines.push(line.trim());
        //         line = '';
        //       }
        //       line += word + ' ';
        //     }
        //     lines.push(line.trim()); // Add the remaining part of the label
        //     return lines;
        //   }
        //   return value; // Return the original label if its length is within the threshold
        // },

        formatter: function (value, timestamp, index) {
          if (value.length > 14) {
            // Split the label into chunks based on spaces and parentheses
            const parts = value.match(/(?:\([^()]*\)|[^\s()]+)/g);
            let lines = [];
            let line = '';
            for (const part of parts) {
              if ((line + part).length > 14) {
                lines.push(line.trim());
                line = '';
              }
              line += part + ' ';
            }
            lines.push(line.trim()); // Add the remaining part of the label
            return lines;
          }
          return value; // Return the original label if its length is within the threshold
        },
      },
      title: {
        text: `*source: ${data.Source}`,
        offsetY: -6,

        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          color: '#000000',
          width: '200px'
          // paddingBottom: '10px',
        },
      },
    },
    yaxis: {
      title: {
        text: `${data.Unit}`,
        align: 'middle',
        rotate: rotateLabel,
        offsetX: 16,
      },
      labels: {
        show: false // Hide the numerical labels on the y-axis
      },
      // reversed: inverted, // Reverse the y-axis to show lower values at the top
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        dataLabels: {
          position: 'top',
          formatter: function (val) {
            return val + '%';
          },
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            colors: ['#000000'],
          },
        },
        borderRadius: 2,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val; // Format tooltip values as percentages
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '16px',
        colors: ['#000000'],
      },
      formatter: function (val) {
        return val.toLocaleString('en-IN'); // Format tooltip values as percentages
      }
    },
    credits: {
      enabled: false, // Remove the credits
    },
    // colors: ['#FF5733', '#3371FF'],
    series: [
      {
        name: `${data.Unit}`,
        data: [`${data.THEN}`, `${data.NOW}`],
      },
      // {
      //   name: 'NOW',
      //   data: [5],
      // },
    ],
  });

  return <Chart options={options} series={options.series} type="bar" height={options.chart.height} width={options.chart.width} />;
};

export default BarGraph;
