import React, { useState } from 'react';
import Header from './Header';
import QueryBox4 from './QueryBox4';
import Loader from './Loader';
import './global.css';
import { useParams } from 'react-router-dom';
import WhitePaperPdf from './../components/Pdf/WhitePaper';


const AppContainer = ({ children }) => {

  const [languageChange, setLanguageChange] = useState('english');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [isNativeLanguage, setIsNativeLanguage] = useState(true);
  const [nativeLanguage, setNativeLanguage] = useState();
  const [nativeScript, setNativeScript] = useState();
  const [samruddhiV1, setSamruddhiV1] = useState(false);
  const [samruddhiV2, setSamruddhiV2] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [language, setLanguage] = useState('english');
  const params = useParams();
  const [sharedStateId, setSharedStateId] = useState(params.state_id);
  const [sharedAreaId, setSharedAreaId] = useState(params.area_id);
  const [pdfShow, setPdfShow] = useState(false);
  const [chartPdfShow, setChartPdfShow] = useState(false);
  const [wpLanguage, setWpLanguage] = useState("english");

  return (
    <div>
      {showLoader && <Loader />}
      <Header className='header' setLanguageChange={setLanguageChange} languageChange={languageChange} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} isNativeLanguage={isNativeLanguage} nativeScript={nativeScript} nativeLanguage={nativeLanguage} setLanguage={setLanguage} language={language} samruddhiV2={samruddhiV2} setPdfShow={setPdfShow} pdfShow={pdfShow} chartPdfShow={chartPdfShow} setChartPdfShow={setChartPdfShow} setWpLanguage={setWpLanguage} wpLanguage={wpLanguage} setDashboard={setDashboard} dashboard={dashboard}/>
      <div className='container_bg-color' style={{ height: samruddhiV1 ? 'calc(100vh - 56px' : '100vh', width: '100%' }}>
       
          <QueryBox4 languageChange={languageChange} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} setShowLoader={setShowLoader} setIsNativeLanguage={setIsNativeLanguage} isNativeLanguage={isNativeLanguage} setNativeLanguage={setNativeLanguage} setNativeScript={setNativeScript} setLanguageChange={setLanguageChange} setLanguage={setLanguage} setSamruddhiV1={setSamruddhiV1} setSamruddhiV2={setSamruddhiV2} samruddhiV1={samruddhiV1} samruddhiV2={samruddhiV2} nativeLanguage={nativeLanguage} sharedStateId={sharedStateId} sharedAreaId={sharedAreaId} setPdfShow={setPdfShow} pdfShow={pdfShow} chartPdfShow={chartPdfShow} setChartPdfShow={setChartPdfShow} setWpLanguage={setWpLanguage} wpLanguage={wpLanguage} setDashboard={setDashboard} dashboard={dashboard}/>  
       

        {children}
      </div>
    </div>
  );
};

export default AppContainer;
