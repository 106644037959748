import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Video from './Video';
import YoutubeEmbed from './YoutubeEmbed';
import { TwitterVideoEmbed } from 'react-twitter-embed';
import ReactPaginate from 'react-paginate';

export default function Accordian(props) {
  let videos = props.videos;
  let shareData = props.shareData;
  const [activeVideo, setActiveVideo] = useState('')
  // console.log(videos, 'videos')


  return (
    <div>
      <Accordion key={videos.video_id} id={`l_${videos.video_id}`}  style={{marginBottom: '4px', borderRadius: '8px'}}>
        <AccordionSummary
          style={{backgroundColor: 'rgba(137,207,240,0.3)'}}
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1a-content"
          // id="panel1a-header"
          id={`l_s_${videos.video_id}`}
          aria-controls={`l_s_${videos.video_id}`}
        >
          <Video videos={videos} shareData={shareData} />
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor: 'rgba(137,207,240,0.1)'}}>
          {
            videos.refferer.toLowerCase() == 'youtube' ? <YoutubeEmbed video_id={videos.video_id} sourceLink={videos.source_link} activeVideo={activeVideo} setActiveVideo={setActiveVideo}/> :
            <TwitterVideoEmbed id={videos.source_link.split('/')[5]} />
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
