import React from 'react';
import AppContainer from './components/Container';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';

import "../node_modules/video-react/dist/video-react.css";
import Samruddhi from "./components/samruddhi_v1/Samruddhi";
import SamruddhiShared from "./components/samruddhi_v1/SamruddhiShared";
import MainDashboard from './components/dashboard/MainDashboard';
import Login from './components/dashboard/Login';
import PrivateRoutes from './components/dashboard/PrivateRoutes';
import AuthProvider from 'react-auth-kit/AuthProvider';
import createStore from 'react-auth-kit/createStore';
import SourceLinks from './components/SourceLinks';

const router = createBrowserRouter([
  {
    path: '/', element: <AppContainer />, errorElement: <ErrorPage />
  },
  {
    path: '/sources', element: <SourceLinks />, errorElement: <ErrorPage />
  },
  {
    path: '/:area_id/:scheme_id', element: <AppContainer />, errorElement: <ErrorPage />
  },
  {
    path: '/samruddhi_v1/:aa_id/:state_id', element: <Samruddhi />, errorElement: <ErrorPage />
  },
  {
    path: '/shared/:area_name/:aa_id/:aa_order/:tab/:accordian', element: <SamruddhiShared />, errorElement: <ErrorPage />
  },
  {
    path: '*', element: <ErrorPage />
  },
  {
    path: "/dashboard",
    element: <PrivateRoutes />,
    children: [
      {
        path: '/dashboard', element: <MainDashboard />, errorElement: <ErrorPage />
      },
    ],
  },
  {
    path: '/dashboard/login', element: <Login />, errorElement: <ErrorPage />
  }
]);

const App = () => {

  const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
});

  return (
    <AuthProvider store={store}><RouterProvider router={router} /></AuthProvider>
  );
}

export default App;
