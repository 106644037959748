import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

const Loader = ({ open }) => {
  const backdropStyle = {
    zIndex: 9999,
    color: '#fff',
  };

  return (
    <Backdrop open={open} style={backdropStyle}>
      <CircularProgress sx={{color: '#fff'}} />
    </Backdrop>
  );
};

export default Loader;
