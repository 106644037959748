import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoBeneficiary from './VideoBeneficiary';
import YoutubeEmbed from './YoutubeEmbed';
import { TwitterVideoEmbed } from 'react-twitter-embed';

export default function AccordianBeneficiary(props) {
  let videos = props.beneficiaryData;
  let shareData = props.shareData;

  const [activeVideo, setActiveVideo] = React.useState('')

  return (
    <div>
      {/* {videos && videos.map((item,index)=>{
        return(  */}
          <div key={`unique_id_${videos.beneficiary_id}`} style={{borderBottom: '1px solid rgba(255,255,255,0.2)'}}>
            {videos.source == null ? <></> :
            <Accordion id={`l_${videos.beneficiary_id}`}>
              <AccordionSummary
              style={{backgroundColor: 'rgba(137,207,240,0.3)'}}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`l_s_${videos.beneficiary_id}`}
                id={`l_s_${videos.beneficiary_id}`}
              >
              {videos.source != null ? (<VideoBeneficiary beneficiaryData={videos} shareData={shareData}/>) : <></>
              }
              </AccordionSummary>
              {videos.source != null &&
                (<AccordionDetails style={{backgroundColor: 'rgba(137,207,240,0.1)'}}>
                  {
                    videos.source.toLowerCase() === 'youtube' ? <YoutubeEmbed video_id={videos.beneficiary_id} sourceLink={videos.source_link} activeVideo={activeVideo} setActiveVideo={setActiveVideo}/> :
                    <TwitterVideoEmbed id={videos.source_link.split('/')[5]} />
                  }
                </AccordionDetails>)
              }
            </Accordion>
            }
          </div>
          {/* )
        })} */}
    </div>
  );
}
